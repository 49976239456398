import React, { useState } from "react";
import {
  Card,
  CardText,
  Title,
  PriceText,
  Features,
  Divider,
  CardButton,
} from "./style";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Tick from '../../assets/tick.svg';

const PricingCard = ({
  title,
  price,
  bgColor,
  textColor,
  buttonColor,
  buttonTextColor,
  status,
  feature1,
  feature2,
  feature3,
}) => {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    const email = localStorage.getItem("email");
    const packageId = price === "35" ? 1 : 2;
    const payload = {
      email: email,
      package_id: packageId,
    };

    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        "https://api.aiflowpro.io/api/auth/session",
        {
          params: payload,
        }
      );

      if (response.data.responseCode === 2000) {
        window.location.href = response.data.response.url;
      } else {
        alert("Failed to create Stripe session");
      }
    } catch (error) {
      console.error("Error creating Stripe session", error);
      alert("An error occurred while creating Stripe session");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Card bgColor={bgColor}>
      <Title textColor={textColor}>{title}</Title>
      {/* <CardText textColor={textColor}>Lorem ipsum dolor sit</CardText> */}
      <PriceText textColor={textColor}>
        €{price}
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "400",
            lineHeight: "3.75rem",
            letterSpacing: "0rem",
          }}
        >
          {status}
        </span>
      </PriceText>
      <Divider />
      <Features>
        <div style={{display:'flex'}}>
          <img alt="" src={Tick} style={{width:'2rem', height:'2rem'}}/>
          <CardText textColor={textColor}>{feature1}</CardText>
        </div>
        <div style={{display:'flex'}}>
          <img alt="" src={Tick} style={{width:'2rem', height:'2rem'}}/>        
          <CardText textColor={textColor}>{feature2}</CardText>
        </div>
        <div style={{display:'flex'}}>
          <img alt="" src={Tick} style={{width:'2rem', height:'2rem'}}/>          
          <CardText style={{marginBottom:'1rem'}} textColor={textColor}>{feature3}</CardText>
        </div>
      </Features>
      <CardButton
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
        onClick={handleButtonClick}
        disabled={loading}
        style={{marginTop: 'auto'}}
      >
        {loading ? (
          <CircularProgress size={21} color="inherit" />
        ) : (
          "Get Started"
        )}
      </CardButton>
    </Card>
  );
};

export default PricingCard;
