import React from "react";
import { MsgBubble, MsgContainer, LoadingText, ChatFileDiv } from "./style";
import Assistant from "../../assets/assistant-white.svg";
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";

const formatContent = (content) => {
  content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  content = content.replace(/(\d+)\. \*\*(.*?)\*\*/g, '<li><strong>$2</strong></li>');
  content = content.replace(/##{1,2} (.*?)\n/g, (_, text) => `<h2 style="margin: 0.75rem 0rem;font-size:16px;">${text}</h2>`);
  content = content.replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>');
  content = content.split('\n').map(line => `<p style="margin: 0rem 0rem;">${line}</p>`).join('');
  content = content.replace(/#/g, '');
  return content;
};

const ChatPopupMsg = ({ isUser, message, files = []  }) => {
  const getFileIcon = (fileType) => {
    if (fileType.includes("pdf")) return PdfIcon;
    if (fileType.includes("word") || fileType.includes("docx")) return DocxIcon;
    return TxtIcon;
  };

  const getFileType = (fileType) => {
    if (fileType.includes("pdf")) return "PDF";
    if (fileType.includes("word") || fileType.includes("docx")) return "DOCX";
    return "TXT";
  };

  return (
    <>
      {isUser && files.length > 0 && (
        <ChatFileDiv>
          {files.map((file, index) => (
            <FileCard key={index} fileName={file.name} fileType={getFileType(file.type)} fileIcon={getFileIcon(file.type)} isPopup={true} hideCloseButton={true} />
          ))}
        </ChatFileDiv>
      )}
      <MsgContainer isUser={isUser}>
        {!isUser && <img src={Assistant} alt="Avatar" />}
        <MsgBubble isUser={isUser}>{message === "Loading..." ? <LoadingText /> : <span dangerouslySetInnerHTML={{ __html: formatContent(message) }} />}</MsgBubble>
      </MsgContainer>
    </>
  )
};

export default ChatPopupMsg;
