import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Container, Content, Header, Heading } from "./Style";
import { Flex, Button, Typography, Input, Upload } from "antd";
import {
  HistoryOutlined,
  PlusOutlined,
  FileOutlined,
  CloseOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import BackIcon from "../../assets/back-icon.svg";
import Star from "../../assets/star.svg";
import Attachment from "../../assets/paperclip.svg";
import TextArea from "antd/es/input/TextArea";

export default function RunSkills() {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleSend = () => {
    console.log("Message:", message);
    console.log("Attached File:", file);
  };

  const handleFileChange = (info) => {
    setFile(info.file);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <Container isOpen={isSidebarOpen}>
      <Flex justify="space-between" align="center">
        <Flex gap={15} align="center">
          <Header onClick={() => navigate("/skills")}>
            <img src={BackIcon} alt="back" />
            <Heading>Run skill</Heading>
          </Header>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/add-skills")}
            style={{ marginTop: "1.8rem" }}
          >
            Start new Chat
          </Button>
        </Flex>

        <Button
          type="default"
          icon={<HistoryOutlined />}
          onClick={() => navigate("/add-skills")}
          style={{ marginTop: "1.8rem", background: "#F1F1F5" }}
        >
          History
        </Button>
      </Flex>
      <Content>
        <Flex
          justify="center"
          align="center"
          style={{ height: "100%", width: "100%" }}
        >
          <Flex vertical align="center" gap={50} style={{ width: "50%" }}>
            <Flex
              gap={10}
              justify="center"
              align="center"
              style={{ width: "100%" }}
            >
              <img
                src={Star}
                alt="logo"
                width={32}
                height={32}
                style={{ marginTop: "0.5rem" }}
              />
              <Typography.Title level={1} color="#313133">
                Good afternoon Ahmed
              </Typography.Title>
            </Flex>
            <Flex
              style={{
                width: "100%",
                border: "1px solid #E0E0E0",
                borderRadius: "16px",
                padding: "8px 16px",
                boxShadow:
                  "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Flex>
                <Upload
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  showUploadList={false}
                >
                  <Button
                    icon={
                      <img
                        src={Attachment}
                        alt="attachment"
                        style={{ width: "16px", height: "16px" }}
                      />
                    }
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                </Upload>
                <TextArea
                  placeholder="Create a leave email"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  bordered={false}
                  style={{
                    flex: 1,
                    fontSize: "16px",
                    height: "132px",
                    resize: "none",
                  }}
                />
                <Button
                  icon={<ArrowUpOutlined />}
                  type="primary"
                  shape="circle"
                  onClick={handleSend}
                  style={{ backgroundColor: "#3F8CFF", border: "none" }}
                />
              </Flex>
              {file && (
                <Flex
                  align="center"
                  style={{
                    marginTop: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  <FileOutlined
                    style={{ fontSize: "18px", color: "#595959" }}
                  />
                  <Typography.Text style={{ marginLeft: "8px", flex: 1 }}>
                    {file.name}
                  </Typography.Text>
                  <Button
                    icon={<CloseOutlined />}
                    type="text"
                    onClick={handleRemoveFile}
                    style={{ color: "#FF4D4F" }}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Content>
    </Container>
  );
}
