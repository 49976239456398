import styled from "styled-components";
import DropDown from "../../assets/arrow-left.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  min-height: 47.25rem;
  margin-right: 0.5rem;
  margin-left: ${({ isOpen }) => (isOpen ? "18.87rem" : "5.62rem")};
  @media (max-height: 850px) {
    margin-left: 1.87rem;
  }
`;

export const Header = styled.div`
  gap: 0.25rem;
  display: flex;
  margin-top: 1.88rem;
  cursor: pointer;
  width: fit-content;
  &:hover {
    text-decoration: underline; /* Underline on hover */
  }
`;

export const Heading = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.04125rem;
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 88vh;
  margin-top: 1.87rem;
  background-color: #fff;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
  @media (max-height: 850px) {
    height: 100%;
    min-height: 80vh;
    max-height: 80vh;
  }
`;

export const ProfileHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderImg = styled.div`
  position: relative;
`;

export const EditButton = styled.div`
  display: inline-flex;
  padding: 0.5625rem 1.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.3125rem;
  background: #f1f1f5;
  color: #696974;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 157.143% */
  cursor: pointer;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 2rem;
`;

export const ProfileItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ProfileLabel = styled.div`
  color: #696974;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ProfileValue = styled.div`
  color: #171725;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 37.5rem;
  height: 29.3125rem;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
`;

export const Button = styled.button`
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background: ${({ primary }) => (primary ? "#007bff" : "#f1f1f5")};
  color: ${({ primary }) => (primary ? "#fff" : "#696974")};
`;

export const ModalCloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const ModelInput = styled.input`
  display: flex;
  width: 15.25rem;
  padding: 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #ebebfd;
  background: #f4f6f6;
  color: #171725;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0rem; /* Adjust this value to move the icon to the left */
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const StyledSelect = styled.select`
  display: flex;
  padding: 0.625rem;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #ebebfd;
  background: #f4f6f6;
  color: #171725;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ButtonDiv = styled.div`
  margin-top: 1.5rem;
  display: flex;
  width: 92%;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: auto;
`;

export const CancelButton = styled.div`
  display: inline-flex;
  padding: 0.5625rem 1.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.3125rem;
  background: #f1f1f5;
  color: #171725;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 157.143% */
  cursor: pointer;
`;
export const SaveButton = styled.div`
  display: inline-flex;
  padding: 0.5625rem 1.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #0062ff;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 157.143% */
  cursor: pointer;
`;
