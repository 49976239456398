import React, {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Icon,
  PageTitle,
  PageDiv,
  Message,
  Button,
} from './Style';
import TickIcon from '../../assets/tick-icon.svg'; // Adjust the path as necessary

const EmailConfirmationSuccess = () => {

  const navigate = useNavigate();
  const handleContinue = () => {
    navigate('/login');
  };

  return (
    <PageDiv>
      <Icon src={TickIcon} alt="Success Icon" />
      <PageTitle>Email Confirmation Successful</PageTitle>
      <Message>Your email has been confirmed successfully</Message>
      <Button onClick={handleContinue}>Continue to login</Button>
    </PageDiv>
  );
};

export default EmailConfirmationSuccess;
