import { useOutletContext } from "react-router-dom";

import SearchIcon from "../../assets/searchicon.svg";
import FilterIcon from "../../assets/filtericon.svg";
import DownArrorIcon from "../../assets/down-arrow.svg";
import MoreIcon from "../../assets/More-horizontal.svg";
import Logo from "../../assets/Logo.svg";
import HamburgerImg from "../../assets/hamburger.svg";

import {
  Header,
  InputContainer,
  IconContainer,
  LeftIcon,
  SearchInput,
  RightIcon,
  UserDiv,
  UserText,
  Hamburger,
  LogoDiv,
  MobileInputContainer,
  HeaderContainer,
  MobileHeaderdiv,
  HeaderText
} from "./Style";

import React from "react";

export default function Navbar( ) {

  const { isSidebarOpen, setIsSidebarOpen } = useOutletContext();
  const handleHamburgerClick = () => {
    // setIsSidebarOpen((prev) => !prev);
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <>
    <HeaderContainer>
      <div style={{display: 'flex',  gap: '1rem',alignItems: 'center'}}>
        <Hamburger src={HamburgerImg} alt="Hamburger Icon" onClick={handleHamburgerClick} />

          <HeaderText>
            Welcome to <span style={{color: '#0062FF'}}>AI-FLOW PRO</span>
          </HeaderText>
      </div>

      {/* <Header>
        <MobileHeaderdiv >
          <LogoDiv src={Logo} alt="Logo" />
        </MobileHeaderdiv>
        <InputContainer>
          <IconContainer>
            <LeftIcon src={SearchIcon} alt="Search Icon" />
          </IconContainer>
          <SearchInput placeholder="Type anything to search" />
          <IconContainer>
            <RightIcon src={FilterIcon} alt="Filter Icon" />
          </IconContainer>
        </InputContainer>
        <div style={{ display: "flex", }}>
          <UserDiv>
            <img
              src={Avatar}
              alt="avatar"
              style={{ borderRadius: "50%", objectFit: "cover" }}
            />
            <UserText>Kurnia Majid</UserText>
            <img src={DownArrorIcon} alt="arrow" />
          </UserDiv>
          <img src={MoreIcon} alt="more" />
        </div>
      </Header>
      <MobileInputContainer>
        <IconContainer>
          <LeftIcon src={SearchIcon} alt="Search Icon" />
        </IconContainer>
        <SearchInput placeholder="Type anything to search" />
        <IconContainer>
          <RightIcon src={FilterIcon} alt="Filter Icon" />
        </IconContainer>
      </MobileInputContainer> */}
    </HeaderContainer>
    </>
  );
}
