import styled from "styled-components";

export const MainDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 289px;
border-radius: 0.625rem;
border: 0px solid #030303;
background: #FFF;
box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.20);
padding: 2rem 1.25rem;
margin-top: 0.56rem;

@media (max-width: 768px) {
  width: 90%;
}

`
export const HeaderDiv = styled.div`
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;

`

export const HeaderImg = styled.img`
    width: 4.8125rem;
    height: 4.8125rem;

    @media (max-width: 768px) {
      width: 3.75rem;
      height: 3.75rem;
    }
`

export const HeaderTitle = styled.div`
color: #171725;
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: 1.375rem; /* 110% */
letter-spacing: 0.00625rem;
margin-left: 1.06rem;
max-width: 8rem;
@media (max-width: 768px){
  font-size: 1rem;
  line-height: 1.25rem; /* 125% */
  letter-spacing: 0.00625rem;
}
`

export const ItemText = styled.div`
color: #696974;
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 171.429% */
letter-spacing: 0.00625rem;
min-height: 4.8125rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`
export const UserInfo = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 0.62rem;
`


export const UserAvatars = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
    color: #8b9ab1;
    font-size: 0.875rem;
  }
`;

export const Avatar = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: -8px; // Overlap avatars
`;

export const Usage = styled.div`
color: #92929D;

font-family: Roboto;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: 0.56rem;
`;

export const Used = styled.div`

display: flex;
align-items: center;
justify-content: center;
width: 4.25rem;
height: 1.75rem;
flex-shrink: 0;
border-radius: 0.3125rem;
background: #F1F1F5;
color: #696974;
text-align: center;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: normal;

`

export const CardButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 8.875rem;
height: 2.375rem;
flex-shrink: 0;
border-radius: 0.625rem;
background: #0062FF;
color: #FAFAFB;
text-align: center;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 2.06rem;
cursor: pointer;
  &:hover {
    opacity: 0.7; 
  }
`

export const ComingSoon = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 8.875rem;
height: 2.375rem;
flex-shrink: 0;
border-radius: 0.625rem;
text-align: center;
background: #F1F1F5;
color: #696974;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 2.06rem;
`