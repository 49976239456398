import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fdfdfd;
`;

export const LoginBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 43.75rem;
  height: 80%;
  max-width: 43.75rem;
  width: 95%;
  border-radius: 2.5rem;
  background: #fff;
  box-shadow: 0px 30px 40px 0px rgba(220, 220, 220, 0.2);
`;

export const ContentDiv = styled.div`
  width: 20.75rem;

  @media (max-width: 360px) {
    width: 90%;
  }
`;
export const Title = styled.h1`
  margin-bottom: 2rem;
  color: #171725;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem; /* 137.5% */
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  // padding: 12px;
  // margin-bottom: 16px;
  // border: 1px solid #ccc;
  // border-radius: 5px;
  // font-size: 16px;

  padding: 0.675rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #ebebfd;
  background: #fff;
  color: #696974;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputLabel = styled.div`
  color: #171725;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.69rem;
`;

export const FormText = styled.div`
  color: #696974;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const SignInButton = styled.div`
  display: flex;
  padding: 0.625rem 0rem 0.5625rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #0062ff;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.88rem;
  margin-bottom: 0.89rem;
  &:hover{
    opacity: 0.8;
  }
`;
export const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


//Email confirmation Styling
export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #fff;
`;

export const Icon = styled.img`
  width: 80px; // Adjust size as needed
  height: 80px; // Adjust size as needed
  margin-bottom: 20px;
`;

export const PageTitle = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
`;

export const Message = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 5px;
`;

export const SubMessage = styled.p`
  font-size: 1rem;
  color: #999;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  background-color: #0046d5;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #003bbd;
  }
`;

export const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
