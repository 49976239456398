// import React, { useState, useRef, useEffect } from "react";
// import { CircularProgress } from "@mui/material";
// import { Container, Header, Heading, Content } from "../Summarize/Style";
// import { useOutletContext, useNavigate } from "react-router-dom";
// import ChatMessage from "./ChatMessage";
// import { AvatarSection, AssistantName, AssistantTitle, ChatBox, ChatDiv, InputContainer, Input, FileDiv, EditImg } from "./style";
// import { UploadBtn } from "../Summarize/Style";
// import BackIcon from "../../assets/back-icon.svg";
// import AvatarSmall from "../../assets/chat-avatar-1-small.svg";
// import ArrowUpIcon from "../../assets/arrow-up-white.svg";
// import PaperClip from "../../assets/paper-clip.svg";
// import FileCard from "../Cards/FileCard";
// import PdfIcon from "../../assets/pdf-large.svg";
// import DocxIcon from "../../assets/docx-large.svg";
// import TxtIcon from "../../assets/txt-large.svg";
// import s3 from "../Environment/Asconfig";
// import EditIcon from "../../assets/edit-avatar.svg";
// import { AVATARS } from "../Constants/Constants";
// import Delete from "../../assets/delete-grey.svg";

// const uploadFileToS3 = async (file) => {
//   const selectedUserId = localStorage.getItem("id");
//   const folderName = `${selectedUserId}/documents`;
//   const key = `${folderName}/${file.name}`;
//   const params = {
//     Bucket: "aiflowpro-fe-userdata",
//     Key: key,
//     Body: file,
//   };
//   const data = await s3.upload(params).promise();
//   return data.Location;
// };

// const formatTimestamp = (date) => {
//   const offset = -date.getTimezoneOffset();
//   const offsetSign = offset >= 0 ? "+" : "-";
//   const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, "0");
//   const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");
// //return date and tie along with gmt offset 
//   return `${date.toISOString().slice(0, 19)}${offsetSign}${offsetHours}:${offsetMinutes}`;
// };

// const getFileTypeFromName = (fileName) => {
//   const extension = fileName.split('.').pop().toLowerCase();
//   if (extension === 'pdf') return 'pdf';
//   if (['doc', 'docx'].includes(extension)) return 'DOCX';
//   if (extension === 'txt') return 'TXT';
//   return 'unknown';
// };

// const formatFileFromUrl = (url) => {
//   const fileName = decodeURIComponent(url.split('/').pop());
//   return {
//     name: fileName,
//     type: getFileTypeFromName(fileName),
//     url: url 
//   };
// };

// const fetchAvatarData = async () => {
//   const userId = localStorage.getItem("id");
//   const response = await fetch(`https://api.aiflowpro.io/api/auth/get-avatar?user_id=${userId}`);
//   const data = await response.json();
//   return data;
// };

// const fetchChatHistory = async () => {
//   const userId = localStorage.getItem("id");
//   const response = await fetch(`https://api.aiflowpro.io/api/auth/avatar-chat?user_id=${userId}`);
//   const data = await response.json();
  
//   if (data.responseCode === 200) {
//     const formattedMessages = data.avatarChats.flatMap(chat => [
//       {
//         id: chat._id + "-query",
//         text: chat.query,
//         isUser: true,
//         files: chat.files.map(fileUrl => formatFileFromUrl(fileUrl))
//       },
//       {
//         id: chat._id + "-response",
//         text: chat.content,
//         isUser: false,
//         files: []
//       }
//     ]);
//     return formattedMessages;
//   }
//   return [];
// };

// const saveChat = async (userId, query, content, files) => {
//   await fetch("https://api.aiflowpro.io/api/auth/avatar-chat", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       user_id: userId,
//       query,
//       content,
//       files,
//     }),
//   });
// };

// const AvatarChat = () => {
//   const { isSidebarOpen } = useOutletContext();
//   const navigate = useNavigate();
//   const [messages, setMessages] = useState([]);
//   const [inputValue, setInputValue] = useState("");
//   const [uploadedFiles, setUploadedFiles] = useState([]);
//   const bottomRef = useRef(null);
//   const [loading, setLoading] = useState(true);
//   const [avatarData, setAvatarData] = useState(null);
//   const [clearingChat, setClearingChat] = useState(false);

//   useEffect(() => {
//     const initializeChat = async () => {
//       try {
//         // Fetch avatar data
//         const avatarResponse = await fetchAvatarData();
//         if (avatarResponse.responseCode === 404) {
//           navigate("/avatar-selection");
//           return;
//         }
//         if (avatarResponse.responseCode === 200) {
//           setAvatarData(avatarResponse.avatar);
//         }

//         const formattedMessages = await fetchChatHistory();
//         setMessages(formattedMessages);
//       } catch (error) {
//         console.error("Error initializing chat:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     initializeChat();
//   }, [navigate]); 
  
//   useEffect(() => {
//     bottomRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [messages]);

//   const handleClearChat = async () => {
//     const userId = localStorage.getItem("id");
//     setClearingChat(true);
    
//     try {
//       const response = await fetch("https://api.aiflowpro.io/api/auth/delete-avatar-chat", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           user_id: userId
//         }),
//       });
      
//       const data = await response.json();
      
//       if (data.responseCode === 200) {
//         setMessages([]);
//       } else {
//         console.error("Failed to clear chat:", data.message);
//       }
//     } catch (error) {
//       console.error("Error clearing chat:", error);
//     } finally {
//       setClearingChat(false);
//     }
//   };

//   const handleFileUpload = (e) => {
//     const files = Array.from(e.target.files).map(file => ({
//       name: file.name,
//       type: file.type,
//       file: file
//     }));
//     setUploadedFiles(files);
//   };

//   const removeFile = (fileName) => {
//     setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
//   };

//   const handleEditClick = () => {
//     navigate("/avatar-selection");
//   };

//   const sendMessage = async (messageContent, files) => {
//     const userMessage = {
//       role: "human",
//       content: messageContent,
//       files: files
//     };
//     const user_id = localStorage.getItem("id") || "";  
//     const user_name = localStorage.getItem("name") || ""; 
  
//     setMessages((prevMessages) => [
//       ...prevMessages,
//       { 
//         id: prevMessages.length + 1, 
//         text: messageContent, 
//         isUser: true,
//         files: files
//       },
//     ]);
  
//     setMessages((prevMessages) => [
//       ...prevMessages,
//       { id: prevMessages.length + 2, text: "Loading...", isUser: false },
//     ]);
  
//     const fileUrls = await Promise.all(uploadedFiles.map((file) => uploadFileToS3(file.file)));
//     try {
//       // Get response from assistant API
//       const response = await fetch("https://assistant-api.aiflowpro.io/chat", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           user_id: user_id,
//           user_name: user_name,
//           messages: [
//             ...messages.map((msg) => ({
//               role: msg.isUser ? "human" : "ai",
//               content: msg.text,
//             })),
//             userMessage,
//           ],
//           timestamp: formatTimestamp(new Date()),
//           s3_document_urls: fileUrls,
//         }),
//       });
//       const data = await response.json();
  
//       // Update messages in UI
//       setMessages((prevMessages) =>
//         prevMessages.map((msg) =>
//           msg.text === "Loading..." ? { ...msg, text: data.content, isUser: false } : msg
//         )
//       );

//       // Save chat to database
//       await saveChat(user_id, messageContent, data.content, fileUrls);
//     } catch (error) {
//       console.error("Error sending message:", error);
//       setMessages((prevMessages) =>
//         prevMessages.map((msg) =>
//           msg.text === "Loading..." ? { ...msg, text: "Error sending message", isUser: false } : msg
//         )
//       );
//     } 
//   };

//   const handleSendMessage = async () => {
//     if (inputValue.trim() === "") return;
//     const currentUploadedFiles = [...uploadedFiles];
//     const currentInputValue = inputValue;
//     setUploadedFiles([]); 
//     setInputValue(""); 
//     await sendMessage(currentInputValue.trim(), currentUploadedFiles);
//   };

//   if (loading) {
//     return (
//       <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   const avatarImage = avatarData ? AVATARS.find(avatar => avatar.imageId === avatarData.imageId)?.src : null;

//   return (
//     <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
//       <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
//         <div style={{ display: "flex" }} onClick={() => navigate("/")}>
//           <img src={BackIcon} alt="back" />
//           <Heading>Avatar Chat</Heading>
//         </div>
//         <UploadBtn 
//           onClick={handleClearChat}
//           disabled={clearingChat}
//           style={{ cursor: clearingChat ? 'default' : 'pointer' }}
//         >
//           {clearingChat ? (
//             <CircularProgress size={20} style={{ marginRight: "0.5rem" }} />
//           ) : (
//             <img src={Delete} alt="Manage Files" style={{height:"1.5rem"}}/>
//           )}
//           Clear Chat
//         </UploadBtn>
//       </Header>
//       <Content style={{ background: "linear-gradient(180deg, #7EAFFD 0%, #3B71C8 100%)" }}>
//         <AvatarSection>
//           <img src={avatarImage} alt="Avatar" style={{ width: "90%" }} />
//           <div style={{display: "flex", gap: "0.5rem"}}>
//             <AssistantName>{avatarData ? avatarData.avatarName : "Michael"}</AssistantName>
//             <EditImg src={EditIcon} alt="edit" onClick={handleEditClick}/>
//           </div>
//           <AssistantTitle>Your Personal Assistant</AssistantTitle>
//         </AvatarSection>
//         <ChatBox>
//           <ChatDiv>
//             {messages.map((message, index) => (
//               <ChatMessage 
//                 key={index} 
//                 text={message.text} 
//                 isUser={message.isUser} 
//                 avatarImage={AvatarSmall} 
//                 files={message.files || []}
//                 userColor="rgba(194, 200, 222, 0.20)" 
//                 userTextColor="#FFF" 
//                 avatarColor="#FFF" 
//                 avatarTextColor="#313133" 
//               />
//             ))}
//             <div ref={bottomRef} />
//           </ChatDiv>
//           <InputContainer style={{ borderRadius: uploadedFiles.length > 0 ? "1.25rem" : "6.25rem" }}>
//             <FileDiv style={{ display: uploadedFiles.length > 0 ? "flex" : "none" }}>
//               {uploadedFiles.map((file, index) => (
//                 <FileCard
//                   key={index}
//                   fileName={file.name}
//                   fileType={file.type.includes("pdf") ? "pdf" : file.type.includes("word") ? "DOCX" : "TXT"}
//                   fileIcon={
//                     file.type.includes("pdf") ? PdfIcon : file.type.includes("word") ? DocxIcon : TxtIcon
//                   }
//                   onRemove={() => removeFile(file.name)}
//                   isPopup={false}
//                 />
//               ))}
//             </FileDiv>
//             <div style={{ display: "flex", width: "100%" }}>
//               <input
//                 type="file"
//                 multiple
//                 accept=".pdf,.doc,.docx,.txt"
//                 style={{ display: "none" }}
//                 id="fileInput"
//                 onChange={handleFileUpload}
//               />
//               <label htmlFor="fileInput" style={{ cursor: "pointer", marginRight: "0.5rem" }}>
//                 <img src={PaperClip} alt="attach file" />
//               </label>
//               <Input 
//                 type="text" 
//                 placeholder="Type Message" 
//                 value={inputValue} 
//                 onChange={(e) => setInputValue(e.target.value)} 
//                 onKeyPress={(e) => { if (e.key === "Enter") { handleSendMessage(); }}} 
//               />
//               <img 
//                 src={ArrowUpIcon} 
//                 alt="send message" 
//                 onClick={handleSendMessage} 
//                 style={{ marginLeft: "auto", cursor: "pointer" }} 
//               />
//             </div>
//           </InputContainer>
//         </ChatBox>
//       </Content>
//     </Container>
//   );
// };

// export default AvatarChat;

import React, { useState, useRef, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Container, Header, Heading, Content } from "../Summarize/Style";
import { useOutletContext, useNavigate } from "react-router-dom";
import ChatMessage from "./ChatMessage";
import { AvatarSection, AssistantName, AssistantTitle, ChatBox, ChatDiv, InputContainer, Input, FileDiv, EditImg, UploadDiv } from "./style";
import { UploadBtn } from "../Summarize/Style";
import BackIcon from "../../assets/back-icon.svg";
import AvatarSmall from "../../assets/chat-avatar-1-small.svg";
import ArrowUpIcon from "../../assets/arrow-up-white.svg";
import PaperClip from "../../assets/paper-clip.svg";
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";
import s3 from "../Environment/Asconfig";
import EditIcon from "../../assets/edit-avatar.svg";
import { AVATARS } from "../Constants/Constants";
import Delete from "../../assets/delete-grey.svg";
import ComputerIcon from "../../assets/computer-icon.svg";
import AvatarDriveFilePicker from "./AvatarDriveFilePicker";

const uploadFileToS3 = async (file) => {
  const selectedUserId = localStorage.getItem("id");
  const folderName = `${selectedUserId}/documents`;
  const key = `${folderName}/${file.name}`;
  const params = {
    Bucket: "aiflowpro-fe-userdata",
    Key: key,
    Body: file,
  };
  const data = await s3.upload(params).promise();
  return data.Location;
};

const formatTimestamp = (date) => {
  const offset = -date.getTimezoneOffset();
  const offsetSign = offset >= 0 ? "+" : "-";
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, "0");
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");

  return `${date.toISOString().slice(0, 19)}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

const getFileTypeFromName = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  if (extension === 'pdf') return 'pdf';
  if (['doc', 'docx'].includes(extension)) return 'DOCX';
  if (extension === 'txt') return 'TXT';
  return 'unknown';
};

const formatFileFromUrl = (url) => {
  const fileName = decodeURIComponent(url.split('/').pop());
  return {
    name: fileName,
    type: getFileTypeFromName(fileName),
    url: url 
  };
};

const fetchAvatarData = async () => {
  const userId = localStorage.getItem("id");
  const response = await fetch(`https://api.aiflowpro.io/api/auth/get-avatar?user_id=${userId}`);
  const data = await response.json();
  return data;
};

const fetchChatHistory = async () => {
  const userId = localStorage.getItem("id");
  const response = await fetch(`https://api.aiflowpro.io/api/auth/avatar-chat?user_id=${userId}`);
  const data = await response.json();
  
  if (data.responseCode === 200) {
    const formattedMessages = data.avatarChats.flatMap(chat => [
      {
        id: chat._id + "-query",
        text: chat.query,
        isUser: true,
        files: chat.files.map(fileUrl => formatFileFromUrl(fileUrl))
      },
      {
        id: chat._id + "-response",
        text: chat.content,
        isUser: false,
        files: []
      }
    ]);
    return formattedMessages;
  }
  return [];
};

const saveChat = async (userId, query, content, files) => {
  await fetch("https://api.aiflowpro.io/api/auth/avatar-chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: userId,
      query,
      content,
      files,
    }),
  });
};

const AvatarChat = () => {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driveFiles, setDriveFiles] = useState([]);
  const bottomRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [avatarData, setAvatarData] = useState(null);
  const [clearingChat, setClearingChat] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const initializeChat = async () => {
      try {
        const avatarResponse = await fetchAvatarData();
        if (avatarResponse.responseCode === 404) {
          navigate("/avatar-selection");
          return;
        }
        if (avatarResponse.responseCode === 200) {
          setAvatarData(avatarResponse.avatar);
        }

        const formattedMessages = await fetchChatHistory();
        setMessages(formattedMessages);
      } catch (error) {
        console.error("Error initializing chat:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeChat();
  }, [navigate]); 
  
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleClearChat = async () => {
    const userId = localStorage.getItem("id");
    setClearingChat(true);
    
    try {
      const response = await fetch("https://api.aiflowpro.io/api/auth/delete-avatar-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId
        }),
      });
      
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setMessages([]);
      } else {
        console.error("Failed to clear chat:", data.message);
      }
    } catch (error) {
      console.error("Error clearing chat:", error);
    } finally {
      setClearingChat(false);
    }
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const pop_id = open ? 'simple-popover' : undefined;

  const handleDriveFiles = async (fileLinks) => {
    handleClosePopover();
    const apiKey = process.env.REACT_APP_API_KEY;
    
    const newDriveFiles = fileLinks.map(fileId => {
      const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${apiKey}`;
      return {
        name: `Drive File ${fileId}`, // This will be updated when metadata is fetched
        type: 'application/octet-stream', // Default type
        isDrive: true,
        driveUrl: fileUrl,
        id: fileId
      };
    });

    try {
      const updatedFiles = await Promise.all(newDriveFiles.map(async (file) => {
        const metadataUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?fields=name,mimeType&key=${apiKey}`;
        const response = await fetch(metadataUrl);
        const metadata = await response.json();
        
        return {
          ...file,
          name: metadata.name,
          type: metadata.mimeType
        };
      }));

      setUploadedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    } catch (error) {
      console.error("Error fetching Drive file metadata:", error);
    }
  };

  const handleFileUpload = (e) => {
    handleClosePopover();
    const files = Array.from(e.target.files).map(file => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false
    }));
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const sendMessage = async (messageContent, files) => {
    const userMessage = {
      role: "human",
      content: messageContent,
      files: files
    };
    const user_id = localStorage.getItem("id") || "";  
    const user_name = localStorage.getItem("name") || ""; 
  
    setMessages((prevMessages) => [
      ...prevMessages,
      { 
        id: prevMessages.length + 1, 
        text: messageContent, 
        isUser: true,
        files: files
      },
    ]);
  
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 2, text: "Loading...", isUser: false },
    ]);

    const fileUrls = await Promise.all(files.map(async (file) => {
      if (file.isDrive) {
        return file.driveUrl;
      } else {
        return uploadFileToS3(file.file);
      }
    }));

    try {
      const response = await fetch("https://assistant-api.aiflowpro.io/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          user_name: user_name,
          messages: [
            ...messages.map((msg) => ({
              role: msg.isUser ? "human" : "ai",
              content: msg.text,
            })),
            userMessage,
          ],
          timestamp: formatTimestamp(new Date()),
          s3_document_urls: fileUrls,
        }),
      });
      const data = await response.json();
  
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.text === "Loading..." ? { ...msg, text: data.content, isUser: false } : msg
        )
      );

      await saveChat(user_id, messageContent, data.content, fileUrls);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.text === "Loading..." ? { ...msg, text: "Error sending message", isUser: false } : msg
        )
      );
    } 
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() === "") return;
    const currentUploadedFiles = [...uploadedFiles];
    const currentInputValue = inputValue;
    setUploadedFiles([]); 
    setInputValue(""); 
    await sendMessage(currentInputValue.trim(), currentUploadedFiles);
  };

  if (loading) {
    return (
      <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  const avatarImage = avatarData ? AVATARS.find(avatar => avatar.imageId === avatarData.imageId)?.src : null;

  return (
    <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
      <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }} onClick={() => navigate("/")}>
          <img src={BackIcon} alt="back" />
          <Heading>Avatar Chat</Heading>
        </div>
        <UploadBtn 
          onClick={handleClearChat}
          disabled={clearingChat}
          style={{ cursor: clearingChat ? 'default' : 'pointer' }}
        >
          {clearingChat ? (
            <CircularProgress size={20} style={{ marginRight: "0.5rem" }} />
          ) : (
            <img src={Delete} alt="Manage Files" style={{height:"1.5rem"}}/>
          )}
          Clear Chat
        </UploadBtn>
      </Header>
      <Content style={{ background: "linear-gradient(180deg, #7EAFFD 0%, #3B71C8 100%)" }}>
        <AvatarSection>
          <img src={avatarImage} alt="Avatar" style={{ width: "90%" }} />
          <div style={{display: "flex", gap: "0.5rem"}}>
            <AssistantName>{avatarData ? avatarData.avatarName : "Michael"}</AssistantName>
            <EditImg src={EditIcon} alt="edit" onClick={() => navigate("/avatar-selection")}/>
          </div>
          <AssistantTitle>Your Personal Assistant</AssistantTitle>
        </AvatarSection>
        <ChatBox>
          <ChatDiv>
            {messages.map((message, index) => (
              <ChatMessage 
                key={index} 
                text={message.text} 
                isUser={message.isUser} 
                avatarImage={AvatarSmall} 
                files={message.files || []}
                userColor="rgba(194, 200, 222, 0.20)" 
                userTextColor="#FFF" 
                avatarColor="#FFF" 
                avatarTextColor="#313133" 
              />
            ))}
            <div ref={bottomRef} />
          </ChatDiv>
          <InputContainer style={{ borderRadius: uploadedFiles.length > 0 ? "1.25rem" : "6.25rem" }}>
            <FileDiv style={{ display: uploadedFiles.length > 0 ? "flex" : "none" }}>
              {uploadedFiles.map((file, index) => (
                <FileCard
                  key={index}
                  fileName={file?.name}
                  fileType={file?.type?.includes("pdf") ? "pdf" : file?.type?.includes("word") ? "DOCX" : "TXT"}
                  fileIcon={
                    file?.type?.includes("pdf") ? PdfIcon : file?.type?.includes("word") ? DocxIcon : TxtIcon
                  }
                  onRemove={() => removeFile(file.name)}
                  isPopup={false}
                />
              ))}
            </FileDiv>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                type="file"
                multiple
                accept=".pdf,.doc,.docx,.txt"
                style={{ display: "none" }}
                id="fileInput"
                onChange={handleFileUpload}
              />
                <img src={PaperClip} alt="attach file" onClick={handleOpenPopover} style={{cursor: "pointer"}}/>
                <Popover
                    id={pop_id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: "#f1f1f5"
                  }}>
                    <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                      <UploadDiv>
                        <img src={ComputerIcon} alt="computer" /> Upload from computer
                      </UploadDiv>
                    </label>
                    <AvatarDriveFilePicker onFilesSelected={handleDriveFiles} />
                  </div>
                  </Popover>
                  <Input 
                    type="text" 
                    placeholder="Type Message" 
                    value={inputValue} 
                    onChange={(e) => setInputValue(e.target.value)} 
                    onKeyPress={(e) => { if (e.key === "Enter") { handleSendMessage(); }}} 
                  />
                  <img 
                    src={ArrowUpIcon} 
                    alt="send message" 
                    onClick={handleSendMessage} 
                    style={{ marginLeft: "auto", cursor: "pointer" }} 
                  />
                </div>
              </InputContainer>
            </ChatBox>
          </Content>
        </Container>
      );
    };
    
    export default AvatarChat;