import React, { useEffect, useState } from "react";
import Email from "../../assets/Email.svg";
import EmailedFailed from "../../assets/cross-icon.svg";
import StatusDisplay from "../../Components/StatusDisplay/StatusDisplay";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const VerificationSuccess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch("https://api.aiflowpro.io/api/auth/verify-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        if (response.ok) {
          setVerificationSuccess(true);
        } else {
          setVerificationSuccess(false);
        }
      } catch (error) {
        setVerificationSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <StatusDisplay
      imgSrc={verificationSuccess ? Email : EmailedFailed}
      title={verificationSuccess ? "Your Email has been verified" : "Email Verification Failed"}
      handleClick={verificationSuccess ? handleLoginClick : handleSignUpClick}
      text={verificationSuccess ? "You can now sign in with your new account 🎉" : "Sign up again"}
      btnText={verificationSuccess ? "Continue to Sign In" : "Continue to Sign Up"}
    />
  );
};

export default VerificationSuccess;
