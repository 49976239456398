import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  PaginationDiv,
  PaginationText,
  CurentPage,
  ToggleImage,
} from "./Style";
import NextIcon from "../../assets/next-icon.svg";
import PrevIcon from "../../assets/previous-icon.svg";

// This line is important for using workerSrc property to resolve the worker file correctly
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DrivePdfViewer = ({ files }) => {
  const [numPages, setNumPages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileIndex, setFileIndex] = useState(0); // Track the current file being viewed

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages((prev) => {
      const updatedNumPages = [...prev];
      updatedNumPages[fileIndex] = numPages;
      return updatedNumPages;
    });
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else if (fileIndex > 0) {
      // Go to the last page of the previous file
      setFileIndex(fileIndex - 1);
      setPageNumber(numPages[fileIndex - 1]);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages[fileIndex]) {
      setPageNumber(pageNumber + 1);
    } else if (fileIndex < files.length - 1) {
      // Go to the next file
      setFileIndex(fileIndex + 1);
      setPageNumber(1);
    }
  };

  return (
    <>
      <Document
        file={`https://drive.google.com/uc?export=download&id=${files[fileIndex]}`}
        onLoadSuccess={onDocumentLoadSuccess}
        style={{ width: "10rem" }}
      >
        <Page pageNumber={pageNumber} scale={0.9} />{" "}
        {/* Ensure the width fits within the max-width of RightPane */}
      </Document>
      <PaginationDiv>
        <ToggleImage
          src={PrevIcon}
          onClick={goToPrevPage}
          disabled={fileIndex === 0 && pageNumber <= 1}
          alt="Previous Page"
        />
        <PaginationText>Page</PaginationText>
        <CurentPage>
          <PaginationText>{pageNumber}</PaginationText>
        </CurentPage>
        <PaginationText>of {numPages[fileIndex]}</PaginationText>
        <ToggleImage
          src={NextIcon}
          onClick={goToNextPage}
          disabled={
            fileIndex === files.length - 1 && pageNumber >= numPages[fileIndex]
          }
          alt="Next Page"
        />
      </PaginationDiv>
    </>
  );
};

export default DrivePdfViewer;
