import Visa from "../../assets/visaCard-icon.svg";
import DC from "../../assets/DinerClub.svg";
import MasterCard from "../../assets/MasterCard.svg";
import AE from "../../assets/AmericanExpress.svg";
import Discover from "../../assets/Discover.svg";
import BcCard from "../../assets/BCcard.svg";
import Avatar1 from "../../assets/chat-avatar-1.svg";
import Avatar2 from "../../assets/chat-avatar-2.svg";
import Avatar3 from "../../assets/chat-avatar-3.svg";
import Avatar4 from "../../assets/chat-avatar-4.svg";
import Avatar5 from "../../assets/chat-avatar-5.svg";

export const CardImgs = {
  visa: Visa,
  amex: AE,
  mastercard: MasterCard,
  dinerclub: DC,
  discover: Discover,
  bccard: BcCard,
};

export const AVATARS = [
  { src: Avatar1, alt: "Avatar 1", imageId: "a1" },
  { src: Avatar2, alt: "Avatar 2", imageId: "a2" },
  { src: Avatar3, alt: "Avatar 3", imageId: "a3" },
  { src: Avatar4, alt: "Avatar 4", imageId: "a4" },
  { src: Avatar5, alt: "Avatar 5", imageId: "a5" },
];
