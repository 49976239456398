import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  width: 33.5rem;
  height: 27.25rem;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  background: #0062ff;

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;

export const IconDiv = styled.div`
  display: flex;
  width: 9.375rem;
  height: 9.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 6.25rem;
  background: #fff;
`;

export const ModalHeader = styled.div`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem; /* 125% */
`;

export const ModalText = styled.div`
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

export const StartButton = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6.25rem;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #313133;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 22rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem;
  background: var(--White, #fff);

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;

export const Header = styled.div`
  color: var(--Gray-900, #101828);

  /* heading-5 20px/The quick brown fox jumps over the lazy dog. */
  font-family: "Wix Madefor Text";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem; /* 140% */
`;
export const ModalButton = styled.div`
  display: flex;
  padding: 0.625rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  display: flex;
  padding: 0.625rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6.25rem;
  border: 1px solid rgba(136, 136, 136, 0.1);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Grey-Text, #060608);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CancelBtn = styled.div`
  display: flex;
  padding: 0.625rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  background: var(--White, #fff);
  color: var(--Grey-Text, #060608);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const PasswordInput = styled.input`
  display: flex;
  padding: 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #ebebfd;
  background: #f4f6f6;
  color: #171725;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
`;

export const PasswordLabel = styled.div`
  color: #696974;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PasswordDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const UploadContent = styled.div`
  display: flex;
  width: 34.5rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;

export const UploadTxt = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem; /* 140% */
`;

export const ModalBtn = styled.div`
  margin-left: 0.75rem;
  display: flex;
  padding: 0.625rem 1.125rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: #0062ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const DragAndDrop = styled.div`
  align-self: stretch;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1.5px dashed #2f80ed;
  background: rgba(47, 128, 237, 0.04);
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Icon = styled.div`
  border-radius: 2.25rem;
  background: rgba(47, 128, 237, 0.08);
  display: inline-flex;
  padding: 0.38463rem;
  align-items: flex-start;
`;

export const UploadText = styled.div`
  color: #313133;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
  margin-top: 1rem;
`;

export const SupportText = styled.div`
  color: #8b9ab1;
  text-align: center;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.03rem;
  margin-top: 0.38rem;
`;

export const FilesDiv = styled.div`
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid #ebebfd;
  padding: 0.8125rem 0.8125rem 0.8125rem 1.3125rem;
  color: #313133;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const ModalTxt = styled.div`
  color: #202020;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
`;

//Add Card Styling
export const AddButton = styled.div`
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 40px;
  border: 1px solid rgba(136, 136, 136, 0.1);
  background: #1674fe;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CardInfo = styled.div`
  color: #424770;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  opacity: 0.9;
`;

export const CardDiv = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  gap: 1.5rem;
`;

export const NotifTxt = styled.div`
  color: #696974;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
`;
