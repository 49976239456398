import styled from "styled-components";

export const MainDiv = styled.div`
  margin-left: ${({ isOpen }) => (isOpen ? "17rem" : "3.75rem")};
  @media (max-height: 850px) {
    margin-left: 0rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img`
  width: 100px;
  height: 100px;
`;

export const Text = styled.p`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #333;
`;

export const Iframe = styled.iframe`
  width: 100%;
  border: none;
`;