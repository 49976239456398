// Style.js
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  background: #fdfdfd;
  justify-content: center;
`;

export const AvatarDiv = styled.div`
  height: 70%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 2000px;
  padding: 4.5rem 2rem;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
  background: #fff;
`;

export const Title = styled.h1`
  color: #313133;
  text-align: right;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Description = styled.p`
  color: #696974;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.00625rem;
  text-align: center;
  max-width: 600px;
  margin-bottom: 3.75rem;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  margin-bottom: 4rem;

  .carousel-container {
    padding: 20px 0;
  }

  .carousel-item {
    display: flex;
    justify-content: center;
    padding: 0 10px;
  }

  .react-multi-carousel-track {
    display: flex;
    align-items: center;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.5rem;
  height: 12.5rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  border: ${(props) =>
    props.isSelected ? "2px solid #0062FF" : "2px solid #E2E3E5"};
  padding: 4px;
  border-radius: 1.5rem;
  &:hover {
    transform: scale(1.05);
  }
`;

export const AvatarImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-color: #f0f7ff;
`;

export const NextButton = styled.button`
  display: flex;
  padding: 0.5625rem 1rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  background: #0062ff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0052cc;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2);
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 10rem;
  display: flex;
  width: 50%;
  padding: 0.7rem 1rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: var(--Components-Input-Global-borderRadiusLG, 0.5rem);
  border: 2px solid #dadce0;
  background: var(--Components-Input-Global-colorBgContainer, #fff);
  &:hover {
    border: 2px solid rgba(0, 98, 255, 0.5);
  }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  color: #313133;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 137.5% */
`;
