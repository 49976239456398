
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainDiv, CenterContent, Icon, Text, Button} from "./Style";
import MailingIcon from '../../assets/mailing.svg'; // Assume the icon is in the same directory

export default function EmailAutomation() {
  // const { isSidebarOpen } = useOutletContext();
  const [showIframe, setShowIframe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");

    const timer = setTimeout(() => {
      const newTabUrl = `https://app.emailflowpro.io/sign-in?email=${email}&password=${password}`;
      window.open(newTabUrl, "_blank");
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleGoToHome = () => {
    navigate("/");
  };

  return (
    <MainDiv>
      {!showIframe && (
        <CenterContent>
          <Icon src={MailingIcon} alt="Mailing Icon" />
          <Text>Opening in new tab ....</Text>
          <Button onClick={handleGoToHome}>Go back to home</Button>
        </CenterContent>
      )}
    </MainDiv>
  );
}

