import React from "react";
import {
  ModalOverlay,
  Content,
  UploadTxt,
  NotifTxt
} from "./Style";
import CloseX from "../../assets/x-close.svg";

const NotificationModal = ({ onClose, notification }) => {
  return (
    <ModalOverlay>
      <Content style={{width: "37.5rem", gap: "0.25rem"}}>
        <div style={{ display: "flex", width: "100%" }}>
          <UploadTxt>{notification.title}</UploadTxt>
          <img
            src={CloseX}
            alt="close"
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
        <NotifTxt>From {notification.sender}</NotifTxt>
        <NotifTxt style={{marginTop: "0.75rem"}}>{notification.content}</NotifTxt>
      </Content>
    </ModalOverlay>
  );
};

export default NotificationModal;
