import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Form,
  LoginBox,
  Title,
  Input,
  FormText,
  SignInButton,
  ContentDiv,
  PasswordWrapper,
} from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import axios from "axios";
import ShowPassword from "../../assets/show-password.svg";

export default function PasswordSetup() {
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(null);
  const [email, setEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false); // New state for submit loading
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility
  const [showRepeatPassword, setShowRepeatPassword] = useState(false); // State to toggle repeat password visibility

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(
          "https://api.aiflowpro.io/api/auth/verify-email",
          { token }
        );

        if (response.status === 200) {
          setVerificationSuccess(true);
          const { email } = response.data;
          setEmail(email);
        } else {
          setVerificationSuccess(false);
        }
      } catch (error) {
        setVerificationSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== repeatPassword) {
      setSnackbarMessage("Passwords do not match");
      setSnackbarOpen(true);
      return;
    }

    setSubmitLoading(true); // Start submit loading

    try {
      const response = await axios.post(
        "https://api.aiflowpro.io/api/auth/change-password",
        {
          email,
          newPassword,
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("Password updated successfully");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          navigate("/resetsuccess");
        }, 2000);
      }
    } catch (error) {
      setSnackbarMessage("Failed to update password");
      setSnackbarOpen(true);
    } finally {
      setSubmitLoading(false); // Stop submit loading
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container>
      <LoginBox>
        <ContentDiv>
          <Title style={{ marginBottom: "0.63rem" }}>Setup New Password</Title>
          <FormText>
            Have you already reset the password?{" "}
            <span
              style={{ color: "#0062FF", cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </span>
          </FormText>

          <Form>
            <PasswordWrapper style={{ marginTop: "3.06rem" }}>
              <Input
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                style={{ width: "100%" }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <img
                src={ShowPassword}
                alt="Toggle Password Visibility"
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  width: "1.5rem",
                  height: "1.5rem",
                  opacity: "0.7",
                  right: "1rem",
                }}
              />
            </PasswordWrapper>
            <FormText style={{ marginTop: "0.69rem" }}>
              Use 8 or more characters with a mix of letters, numbers and
              symbols.
            </FormText>
            <PasswordWrapper style={{ marginTop: "1.31rem"}}>
              <Input
                type={showRepeatPassword ? "text" : "password"}
                placeholder="Repeat Password"
                style={{ width: "100%" }}
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                required
              />
              <img
                src={ShowPassword}
                alt="Toggle Password Visibility"
                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  width: "1.5rem",
                  height: "1.5rem",
                  opacity: "0.7",
                  right: "1rem",
                }}
              />
            </PasswordWrapper>
            <SignInButton
              type="submit"
              disabled={submitLoading}
              onClick={handleSubmit}
            >
              {submitLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </SignInButton>
          </Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
        </ContentDiv>
      </LoginBox>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={
          snackbarMessage === "Password updated successfully"
            ? "success"
            : "error"
        }
      />
    </Container>
  );
}
