import React, { useState } from "react";
import {
  ModalContent,
  ModalOverlay,
  IconDiv,
  ModalHeader,
  ModalText,
  StartButton,
} from "./Style";
import RocketIcon from "../../assets/rocket-icon.svg";
import Tour from "../Onboarding/Tour";

const OnboardModal = ({ onClose, setRunTour }) => {

  const handleStartTour = () => {
    setRunTour(true);
    onClose(); // Close the modal
  };

  return (
    <>
      <ModalOverlay>
        <ModalContent>
          <IconDiv>
            <img src={RocketIcon} alt="onboard" />
          </IconDiv>
          <div style={{ display: "flex", flexDirection: "column", gap: '0.75rem'}}>
            <ModalHeader>Welcome to AI Flow Pro</ModalHeader>
            <ModalText>
              Let’s start with a quick product tour and we will have you up and running in no time
            </ModalText>
          </div>
          <StartButton onClick={handleStartTour}>Get Started</StartButton>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default OnboardModal;
