// import React, { useState, useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
// import { MainDiv } from "./Style";
// import { Iframe } from "../Iframe/Style";
// export default function TestEmail() {
//   const { isSidebarOpen } = useOutletContext();
//   const [iframeSrc, setIframeSrc] = useState("");

//   useEffect(() => {
    // const email = localStorage.getItem("email");
    // const password = localStorage.getItem("password");
//     if (email && password) {
//       setIframeSrc(`https://app.emailflowpro.io/sign-in/?email=${email}&password=${password}`);
//     }
//   }, []);

//   return (
//     <MainDiv isOpen={isSidebarOpen}>
//       <Iframe
//         src={iframeSrc}
//         width="100%"
//         style={{ minHeight: "810px", border: "none", height: "100vh" }}
//       />{" "}
//     </MainDiv>
//   );
// }

import React, { useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { MainDiv } from "./Style";
import { Container, Header, Heading, Content } from '../Summarize/Style';
import { Iframe } from "../Iframe/Style";
import BackIcon from "../../assets/back-icon.svg";
export default function TestEmail() {
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const [iframeSrc, setIframeSrc] = useState(
    `https://app.emailflowpro.io/sign-in/?email=${email}&password=${password}`
  );
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  return (
    <Container isOpen={isSidebarOpen} style={{marginRight: "1.4rem"}}>
        <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }} onClick={() => navigate("/")}>
            <img src={BackIcon} alt="back" />
            <Heading>Email Automation</Heading>
          </div>
        </Header>
        <Content>
          <Iframe src={iframeSrc} />
        </Content>
    </Container>
  );
}
