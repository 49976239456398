import styled from "styled-components";

export const PaginationDiv = styled.div`
display: flex;
height: 36px;

justify-content: center;
align-items: center;

border-radius: 0px 0px 10px 10px;
border: 1px solid #EBEBFD;
background: #F4F6F6;
box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.20);
gap: 8px;
margin-top: auto; /* Push it to the bottom of RightPane */
`

export const PaginationText= styled.div`
color: #696974;
text-align: center;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: normal;
`

export const CurentPage = styled.div`
display: flex;
padding: 0.375rem 0.75rem;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.1875rem;
background: #FFF;`

export const ToggleImage = styled.img`
cursor: pointer;
&:hover {
    opacity: 0.7;
  }
`