import React, { useEffect, useState, useRef } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import s3 from "../Environment/Asconfig";
import jsPDF from "jspdf";
import RemovePdfModal from "../modals/RemovePdfModal"; // Import the modal
import {
  Container,
  DragAndDrop,
  HeaderText,
  IconDiv,
  LeftPane,
  LeftPaneHeader,
  RightPane,
  SupportText,
  UploadText,
  ChatDiv,
  ChatBox,
  ChatText,
  PdfContainer,
  Header,
  Heading,
  ContentDiv,
  ScrollableChat,
  ClearButton,
  OrDivider,
} from "./Style";
import PdfViewer from "../../Components/PdfViewer/PdfViewer";
import UploadIcon from "../../assets/upload.svg";
import BackIcon from "../../assets/back-icon.svg";
import CrossIcon from "../../assets/cross-in-square.svg";
import DriveFilePicker from "./DriveFilePicker";
import DriveFileUploader from "./DriveFileUploader";
import SummarizeDriveFilePicker from "./SummarizeDriveFilePicker";
import { toast } from "react-toastify";
import SnackbarAlert from "../Alerts/SnackbarAlert";

const formatBlogPostContent = (text) => {
  // Split text by double new lines for paragraphs
  const paragraphs = text
    .split(/\n\n+/)
    .map((paragraph, idx) => {
      // Format text with inline styles and handle list items and links
      return paragraph
        .split(/\n/)
        .map((line, idx) => {
          // Apply bold formatting to all occurrences of **bold text**
          line = line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

          if (line.startsWith("**") && line.endsWith("**")) {
            return `<h3 key=${idx}>${line.slice(2, -2)}</h3>`;
          } else if (line.startsWith("- ")) {
            return `<li key=${idx}>${line.slice(2)}</li>`;
          } else if (line.match(/\[([^\]]+)\]\(([^)]+)\)/)) {
            return line.replace(
              /\[([^\]]+)\]\(([^)]+)\)/g,
              '<a href="$2">$2</a>'
            );
          } else {
            return `<p key=${idx}>${line}</p>`;
          }
        })
        .join("");
    })
    .map((paragraph, idx) => `<div key=${idx}>${paragraph}</div>`)
    .join("");

  // Wrap list items in <ul> tags
  return paragraphs.replace(/(<li>.*?<\/li>)/g, "<ul>$1</ul>");
};

export default function Summarize() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showRemoveFileModal, setShowRemoveFileModal] = useState(false); // State for modal visibility
  const { isSidebarOpen } = useOutletContext();
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [objectKey, setObjectKey] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const navigate = useNavigate();
  const scrollableChatRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile.name);
    if (selectedFile && selectedFile.type === "application/pdf") {
      setLoadingFile(true);
      setFile(selectedFile);
      console.log("file", selectedFile);
      const formData = new FormData();
      console.log("1-----", formData);
      formData.append("file", selectedFile);

      fetch("https://flowproai.dialect-ai.com/summarize", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Response from server:", data);
          setObjectKey(data.model_path);
          setConversation([{ text: data.summary }]);
        })
        .catch((error) => {
          console.error("Error uploading PDF:", error);
        })
        .finally(() => {
          setLoadingFile(false);
        });

      setFile(URL.createObjectURL(selectedFile));
      setError(null);
    } else {
      setError("Please upload a valid PDF file.");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setLoadingFile(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      fetch(
        "http://search-sphere-documents-env.eba-attcu4m4.eu-west-1.elasticbeanstalk.com/summarize",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Response from server:", data);
          setObjectKey(data.model_path);
          setConversation([{ text: data.summary }]);
        })
        .catch((error) => {
          console.error("Error uploading PDF:", error);
        })
        .finally(() => {
          setLoadingFile(false);
        });

      setFile(URL.createObjectURL(selectedFile));
      setError(null);
    } else {
      setError("Please upload a valid PDF file.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const uploadFile = async () => {
    if (file) {
      const userId = localStorage.getItem("id");
      const folderName = `${userId}/documents`; // Replace with actual user ID or dynamically fetch it
      const key = `${folderName}/${file.name}`;
      // console.log(file.name);
      const params = {
        Bucket: "aiflowpro-fe-userdata",
        Key: key,
        Body: file,
      };
      try {
        const data = await s3.upload(params).promise();
        console.log("File uploaded successfully:", data);
        console.log("File uploaded successfully:", data.Location);
      } catch (err) {
        console.error("Error uploading file:", err);
      }
    }
  };

  const handleDownloadPdf = () => {
    const originalFileName = fileName.split(".pdf")[0];
    const summaryFileName = `${originalFileName}_summary.pdf`;
    setLoadingMessage(true);
    let doc = new jsPDF("p", "pt", "a4");
    const htmlContent = conversation
      .map((msg, index) => {
        const formattedText = formatBlogPostContent(msg.text);
        return `<div style="width:34rem; letter-spacing:0.5px; padding:2rem; font-size:0.7rem">${formattedText}</div>`;
      })
      .join("");

    doc.html(htmlContent, {
      callback: function (pdf) {
        pdf.save(summaryFileName);
        setLoadingMessage(false);
      },
      x: 10,
      y: 10,
    });
  };

  useEffect(() => {
    if (scrollableChatRef.current) {
      scrollableChatRef.current.scrollTop =
        scrollableChatRef.current.scrollHeight;
    }
  }, [conversation]);

  // Clear chat by setting conversation to an empty array
  const handleClearMessages = () => {
    setConversation([]);
  };

  // Handle the removal of the file
  const handleRemoveFile = () => {
    setFile(null);
    handleClearMessages();
  };

  // Show the remove file modal
  const handleShowRemoveFileModal = () => {
    setShowRemoveFileModal(true);
  };

  // Hide the remove file modal
  const handleHideRemoveFileModal = () => {
    setShowRemoveFileModal(false);
  };
  const handleGoogleDriveFiles = async (fileLinks) => {
    if (fileLinks.length > 0) {
      setLoadingFile(true);
      const formData = new FormData();

      const selectedFileId = fileLinks[0]; // Get the first file link
      const apiKey = process.env.REACT_APP_API_KEY;

      try {
        // Fetch the file metadata to get the filename
        const fileMetadata = await fetch(
          `https://www.googleapis.com/drive/v3/files/${selectedFileId}?fields=name&key=${apiKey}`
        ).then((response) => response.json());

        const fetchedFileName = fileMetadata.name; // Get the filename from metadata
        setFileName(fetchedFileName); // Set the filename state

        // Fetch the PDF file
        const pdfBlob = await fetch(
          `https://www.googleapis.com/drive/v3/files/${selectedFileId}?alt=media&key=${apiKey}`
        ).then((response) => response.blob());

        formData.append("file", pdfBlob, fetchedFileName); // Append blob with fetched filename

        fetch("https://flowproai.dialect-ai.com/summarize", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            setObjectKey(data.model_path);
            setConversation([{ text: data.summary }]);
          })
          .catch((error) => {
            console.error("Error uploading PDF:", error);
          })
          .finally(() => {
            setLoadingFile(false);
          });

        setFile(URL.createObjectURL(pdfBlob));
        setError(null);
      } catch (error) {
        console.error("Error fetching file:", error);
        setLoadingFile(false);
      }
    }
  };

  return (
    <Container isOpen={isSidebarOpen}>
      <Header onClick={() => navigate("/")}>
        <img src={BackIcon} alt="back" />
        <Heading>Summarize</Heading>
      </Header>
      <ContentDiv>
        <LeftPane>
          <div style={{ display: "flex", gap: "20px", marginBottom: "0.5rem" }}>
            <LeftPaneHeader style={{ width: "100%" }}>
              <HeaderText>Summary</HeaderText>
            </LeftPaneHeader>
            {conversation.length > 0 && (
              <>
                <ClearButton
                  onClick={handleDownloadPdf}
                  disabled={loadingMessage}
                >
                  {loadingMessage ? <CircularProgress size={24} /> : "Download"}
                </ClearButton>
                <DriveFileUploader
                  file={file}
                  fileName={fileName}
                  onUploadComplete={() => (
                    <SnackbarAlert
                      message={`${fileName} uploaded successfully!`}
                      severity="success"
                      autoHideDuration={3000}
                    ></SnackbarAlert>
                  )}
                />
              </>
            )}
          </div>
          <ScrollableChat ref={scrollableChatRef}>
            {loadingFile ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
                <ChatText style={{ marginLeft: "1rem" }}>
                  AI model loading
                </ChatText>
              </div>
            ) : file ? (
              conversation.map((msg, index) => (
                <ChatDiv key={index}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ChatBox>
                      <ChatText
                        dangerouslySetInnerHTML={{
                          __html: formatBlogPostContent(msg.text),
                        }}
                      />
                    </ChatBox>
                  </div>
                </ChatDiv>
              ))
            ) : null}
          </ScrollableChat>
        </LeftPane>
        <RightPane>
          {file ? (
            <PdfContainer>
              <PdfViewer file={file} />
              <img
                src={CrossIcon}
                alt="close"
                style={{
                  position: "absolute",
                  zIndex: "1000",
                  right: "5px",
                  top: "5px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  opacity: "0.6",
                }}
                onClick={handleShowRemoveFileModal} // Show the modal on click
              />
            </PdfContainer>
          ) : (
            <>
              <DragAndDrop
                onClick={() => document.getElementById("fileInput").click()}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <input
                  id="fileInput"
                  type="file"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <IconDiv>
                  <img src={UploadIcon} alt="upload" />
                </IconDiv>
                <UploadText>
                  Drag and drop files, or
                  <span style={{ color: "#2F80ED" }}> Browse</span>
                </UploadText>
                <SupportText>Support pdf files</SupportText>
              </DragAndDrop>
              <OrDivider>OR</OrDivider>
              <SummarizeDriveFilePicker
                onFilesSelected={handleGoogleDriveFiles}
              />
            </>
          )}
        </RightPane>
      </ContentDiv>
      {showRemoveFileModal && (
        <RemovePdfModal
          onCancel={handleHideRemoveFileModal}
          onRemove={() => {
            handleRemoveFile();
            handleHideRemoveFileModal();
          }}
        />
      )}
    </Container>
  );
}
