import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  min-height: 47.25rem;
  margin-right: 0.5rem;
  margin-left: ${({ isOpen }) => (isOpen ? "18.87rem" : "5.62rem")};
  @media (max-height: 850px) {
    margin-left: 1.87rem;
  }
`;
export const Header = styled.div`
  gap: 0.25rem;
  display: flex;
  margin-top: 1.88rem;
  cursor: pointer;
`;

export const ContentDiv = styled.div`
  display: flex;
  gap: 1.25rem;
  height: 88vh;
  margin-top: 1.87rem;

  @media (max-height: 850px) {
    height: 100%;
    min-height: 80vh;
    max-height: 80vh;
  }
`;

export const Heading = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.04125rem;
  &:hover {
    text-decoration: underline; /* Underline on hover */
  }
`;

export const LeftPane = styled.div`
  flex: 57.2%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ebebfd;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
`;

export const LeftPaneHeader = styled.div`
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #f4f6f6;
`;

export const HeaderText = styled.div`
  color: #171725;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: 0.00625rem;
`;

export const RightPane = styled.div`
  flex: 39.9%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ebebfd;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
`;

export const RightPaneContainer = styled.div``;

export const DragAndDrop = styled.div`
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1.5px dashed #2f80ed;
  background: rgba(47, 128, 237, 0.04);
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const IconDiv = styled.div`
  border-radius: 2.25rem;
  background: rgba(47, 128, 237, 0.08);
  display: inline-flex;
  padding: 0.38463rem;
  align-items: flex-start;
`;

export const UploadText = styled.div`
  color: #313133;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
  margin-top: 1rem;
`;

export const SupportText = styled.div`
  color: #8b9ab1;
  text-align: center;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.03rem;
  margin-top: 0.38rem;
`;

export const InputContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid #ebebfd;
  padding: 0.8125rem 0.8125rem 0.8125rem 1.3125rem;
  display: flex;
  align-items: center;
  margin-top: auto;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  color: #9393a4;
  width: 80%;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 125% */
`;

export const ChatDiv = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  overflow-y: auto; /* Make it scrollable */
`;

export const ChatBox = styled.div`
  border-radius: 0.75rem;
  border: 1px solid rgba(9, 9, 9, 0.1);
  background: #f4f6f6;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const StrongText = styled.div`
  color: #40414f;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
`;

export const ChatText = styled.div`
  color: #40414f;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
`;

export const QuestionDiv = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(9, 9, 9, 0.1);
  background: #fff;
`;

export const PdfContainer = styled.div`
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  overflow: hidden; /* Hide overflowing content */
  border-radius: 10px; /* Match border radius of RightPane */
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UserChatDiv = styled.div`
  display: flex;
  margin-top: 1rem;
  overflow-y: auto;
  justify-content: flex-end;
  & > img {
    margin-left: 0.5rem; /* Add margin to the left of the image */
  }
`;

export const UserChatBox = styled.div`
  border-radius: 0.75rem;
  border: 1px solid rgba(9, 9, 9, 0.1);
  background: #e0f7fa;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const ScrollableChat = styled.div`
  overflow-y: auto;
  flex: 1;
  scrollbar-width: thin;
`;

export const ClearButton = styled.div`
  display: inline-flex;
  padding: 0.5625rem 1.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: rgb(241, 241, 245);
  color: rgb(105, 105, 116);
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  cursor: pointer;
`;

const loading = keyframes`
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  66% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
`;

export const LoadingText = styled.div`
  color: #40414f;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  &:after {
    content: "Loading";
    animation: ${loading} 1.5s infinite;
  }
`;

export const UploadBtn = styled.div`
  display: inline-flex;
  padding: 0.5625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: #f1f1f5;
  color: #696974;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 157.143% */
`;

export const Content = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  height: 88vh;
  margin-top: 1.87rem;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);

  @media (max-height: 850px) {
    height: 100%;
    min-height: 80vh;
    max-height: 80vh;
  }
`;

export const QuestionTxt = styled.div`
  color: #313133;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem; /* 120% */
  margin-top: 1.5rem;
`;

export const SubHeading = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: #313133;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 155.556% */
`;

export const ChatTxt = styled.div`
  color: #40414f;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  margin-bottom: 1.5rem;
`;

export const CenterPane = styled.div`
  display: flex;
  flex-direction: column;
  width: 51.66%;
  padding: 2.5rem 0rem 1.25rem 0rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  gap: 0.44rem;
  @media (max-width: 1420px) {
    grid-template-columns: repeat(3, 0fr);
  }
  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 0fr);
  }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(4, 0fr);
  }
  ${(props) =>
    props.isOpen &&
    css`
      @media (max-width: 1628px) {
        grid-template-columns: repeat(3, 0fr);
      }
      @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 0fr);
      }
    `}

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  ${(props) =>
    props.isOpen &&
    css`
      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
      }
    `}
`;

export const SourceDiv = styled.div`
  dispay: flex;
  flex-direction: column;
`;

export const SourceTxt = styled.div`
  color: #313133;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 155.556% */
`;

export const DriveUploadBtn = styled.button`
  display: inline-flex;
  padding: 0.5625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: #f1f1f5;
  color: #696974;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 157.143% */
  cursor: pointer;
  border: none;
  outline: none;
  &:hover {
    background-color: #e0e0e5; /* Slightly darker on hover */
  }
`;

export const OrDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #b0b0b0; /* Light gray color for the text */
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 1.5rem 0; /* Vertical margin for spacing */

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #e0e0e0; /* Light gray color for the line */
    margin: 0 0.75rem; /* Horizontal space between text and lines */
  }
`;
