import styled from "styled-components";

export const ContentDiv =styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
position: relative;
`
export const CardText = styled.div`
color: #FFF;
font-family: Roboto;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
margin-bottom: 0.5rem;
`

export const Heading = styled.div`
color: #FFF;
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 700;
line-height: 1.75rem; /* 140% */
margin-bottom: 0.5rem;
`