import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Typography } from "antd";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import BackIcon from "../../assets/back-icon.svg";
import SkillsTable from "./SkillsTable";
import { Container, Content, Header, Heading } from "./Style";

export default function Skills() {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();

  return (
    <Container isOpen={isSidebarOpen}>
      <Header onClick={() => navigate("/")}>
        <img src={BackIcon} alt="back" />
        <Heading>Skills</Heading>
      </Header>
      <Content>
        <Flex
          style={{ margin: "0px 36px 0px 36px", paddingTop: "24px" }}
          gap="middle"
          align="center"
          justify="space-between"
        >
          <Typography.Title style={{ margin: 0 }} level={3}>
            My Skill
          </Typography.Title>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/add-skills")}
          >
            Add new Skill
          </Button>
        </Flex>
        <Divider />
        <div style={{ padding: " 0px 36px 0px 36px" }}>
          <SkillsTable />
        </div>
      </Content>
    </Container>
  );
}
