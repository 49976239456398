import React, { forwardRef, useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  HeaderDiv,
  ListHeader,
  SidebarHeader,
  TableText,
  SidebarBtn,
  RowDiv,
  RowText,
  DeleteDiv,
  DeleteTxt,
} from "./Style";
import CloseX from "../../assets/x-close.svg";
import EmptySquare from "../../assets/square.svg";
import DeleteIcon from "../../assets/delete.svg";
import UploadIcon from "../../assets/upload-cloud.svg";
import CheckIcon from "../../assets/blue-check.svg";
import UploadModal from "../modals/UploadModal";
import RemoveFileModal from "../modals/RemoveFileModal";
import { CircularProgress } from "@mui/material";

const GenSearchSideBar = forwardRef(({ onClose, onUpdate }, props, ref) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showRemoveFileModal, setShowRemoveFileModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currFileUrls, setCurrFileUrls] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [remainingFileUrls, SetRemainingFileUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalFileSizeMB, setTotalFileSizeMB] = useState(0);


  const fetchFiles = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get("https://api.aiflowpro.io/api/auth/get-files", {
        params: { user_id: id },
      });
  
      if (response.data && response.data.responseCode === 200) {
        setUploadedFiles(response.data.files);


        const totalSizeBytes = response.data.files.reduce((acc, file) => acc + file.fileSize, 0);
        const totalSizeMB = totalSizeBytes / 1024 / 1024;
        setTotalFileSizeMB(totalSizeMB.toFixed(2));
      }
    } catch (error) {
      console.error("Error fetching files", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchFiles();
  }, []);
  

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setCurrFileUrls(uploadedFiles.map(file => file.fileUrl));
    setModalOpen(true); // Assuming you store the fileUrls in a state
  };

  const handleShowRemoveFileModal = () => {
    setCurrFileUrls(uploadedFiles.map(file => file.fileUrl));
    setShowRemoveFileModal(true);
  };
  
  const handleShowRemoveSingleFileModal = (file) => {
    setCurrFileUrls(uploadedFiles.map(file => file.fileUrl));
    setFilesToDelete([file]); // Set the single file to delete
    setShowRemoveFileModal(true);
  };

  const handleHideRemoveFileModal = () => {
    setFilesToDelete([]); // Clear the files to delete
    setShowRemoveFileModal(false);
  };

  const handleFileSelect = (file) => {
    if (filesToDelete.includes(file)) {
      setFilesToDelete(filesToDelete.filter(f => f !== file));
    } else {
      setFilesToDelete([...filesToDelete, file]);
    }
  };

  const handleSelectAll = () => {
    if (filesToDelete.length === uploadedFiles.length) {
      setFilesToDelete([]);
    } else {
      setFilesToDelete(uploadedFiles);
    }
  };

  const handleDeleteClick = () => {
    handleShowRemoveFileModal();
  };

  const isSelected = (file) => filesToDelete.includes(file);

  return (
    <>
      <Container ref={ref}>
        <HeaderDiv>
          <SidebarHeader>Your Files</SidebarHeader>
          <SidebarBtn onClick={handleModalOpen}>
            <img src={UploadIcon} alt="upload" />
            Upload
            </SidebarBtn>
          <img
            src={CloseX}
            alt="close"
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={onClose}
          />
        </HeaderDiv>
        {filesToDelete.length > 0 && (
          <DeleteDiv>
            <DeleteTxt>{filesToDelete.length} rows selected</DeleteTxt>
            <SidebarBtn
              style={{ background: "#E5322D", marginLeft: "auto" }}
              onClick={handleDeleteClick}
            >
              Delete
            </SidebarBtn>
          </DeleteDiv>
        )}
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", padding: "1.5rem" }}>
            <ListHeader>
              <img
                src={filesToDelete.length === uploadedFiles.length ? CheckIcon : EmptySquare}
                alt="select"
                style={{ marginRight: "0.75rem", cursor: "pointer" }}
                onClick={handleSelectAll}
              />
              <TableText style={{ flex: "1" }}>Name</TableText>
              <TableText style={{ flex: "1" }}>File Size</TableText>
              <TableText style={{ flex: "1" }}>Upload Time</TableText>
              <TableText style={{ flex: "1", textAlign: "center" }}>Actions</TableText>
            </ListHeader>
            {uploadedFiles.map((file, index) => (
              <RowDiv key={index}>
                <img
                  src={isSelected(file) ? CheckIcon : EmptySquare}
                  alt="select"
                  style={{ marginRight: "0.75rem", cursor: "pointer" }}
                  onClick={() => handleFileSelect(file)}
                />
                <RowText style={{ flex: "1" }}>{file.fileName}</RowText>
                <RowText style={{ flex: "1" }}>{(file.fileSize / 1024000.0).toFixed(2)} Mb</RowText>
                <RowText style={{ flex: "1" }}>{new Date(file.uploadTime).toLocaleString()}</RowText>
                <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
                  <img
                    src={DeleteIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleShowRemoveSingleFileModal(file)}
                  />
                </div>
              </RowDiv>
            ))}
          </div>
        )}
      </Container>
      {isModalOpen && (
        <UploadModal
          onClose={handleModalClose}
          currFileUrls={currFileUrls}
          onUpdate={() => {
            fetchFiles();
            onUpdate(); // Call the onUpdate prop when update occurs
          }}
          totalFileSizeMB={totalFileSizeMB}
        />
      )}
      {showRemoveFileModal && (
        <RemoveFileModal
          file={filesToDelete}
          onClose={handleHideRemoveFileModal}
          onUpdate={() => {
            fetchFiles();
            onUpdate(); 
          }}
          currFileUrls={currFileUrls}
        />
      )}
    </>
  );
});

export default GenSearchSideBar;
