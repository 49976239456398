import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  width: 98%;
  @media (max-width: 768px) {
    padding: 1rem 0rem; 
    margin-top: 0rem; 
  }
`;


export const HeaderText = styled.div`
color: #313133;
font-family: Inter;
font-size: 1.75rem;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.0525rem;
@media (max-width: 768px) {
  font-size: 1rem;

}

`
export const HeaderContainer = styled.div`
width: 100%;
margin-top: 3.4rem;
`;

export const MobileHeaderdiv = styled.div`
display: none;
@media (max-width: 768px) {
  display: block; 
  display: flex;
  gap: 0.25rem;
  
}
`
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #ebebfd;
  padding: 0.5rem;
  background: #fff;
  width: 65%;
  @media (max-width: 1360px) {
    width: 60%;
  }

  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileInputContainer = styled.div`
  display: none;
  align-items: center;
  max-width: 45.5rem;
  border-radius: 0.5rem;
  border: 1px solid #ebebfd;
  padding: 0.5rem;
  background: #fff;
  width: 90%;
  margin: 1rem auto;

  @media (max-width: 768px) {
    display: flex;
    width: 98%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;

export const LeftIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const RightIcon = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
  color: #8b9ab1;
  font-family: Inter, sans-serif;

  &::placeholder {
    color: #8b9ab1;
  }
`;

export const UserDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4rem;

  @media (max-width: 1360px) {
    margin-right: 2rem;

  }
 
  @media (max-width: 768px) {
    margin-right: 0.75rem;
     margin-top: 0.75rem;
  }

`;

export const UserText = styled.div`
  width: max-content;
  color: #313133;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 0.5rem;
  margin-left: 0.25rem;

  letter-spacing: -0.055rem;
  @media (max-width: 768px) {
   display: none;
  }
`;

export const Hamburger = styled.img`
width: 1.5rem;
height: 1.5rem;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const LogoDiv = styled.img`
  width: 7.5rem;
  height: 2.5rem;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
