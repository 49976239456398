import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, Header, Heading } from "../Summarize/Style";
import NotificationModal from "../modals/NotificationModal";
import moment from 'moment';
import { MarkText, Content, NotificationItem, NotifImg, Title, TextDiv, Desc, StatusDiv, Timestamp } from "./Style";
import BackIcon from "../../assets/back-icon.svg";
import RedCircle from "../../assets/red-circle.svg";
import NotifIcon from "../../assets/notification.svg";
import UnreadIcon from "../../assets/unread-mail.svg";
import ReadIcon from "../../assets/read-mail.svg";
import DeleteIcon from "../../assets/delete.svg";

const NotificationPage = () => {
  const { isSidebarOpen } = useOutletContext();
  const { notifUpdated, setNotifUpdated } = useOutletContext();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [deletingIds, setDeletingIds] = useState(new Set());
  const [markingReadIds, setMarkingReadIds] = useState(new Set());
  const [markingAllRead, setMarkingAllRead] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userId = localStorage.getItem('id');
        if (!userId) return;
        const response = await fetch(
          `https://api.aiflowpro.io/api/notification?userId=${userId}`
        );
        const data = await response.json();
  
        if (data.responseCode === 200) {
          setNotifications(data.notifications);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  const handleDelete = async (notificationId) => {
    setDeletingIds(prev => new Set([...prev, notificationId]));
    
    try {
      const response = await fetch('https://api.aiflowpro.io/api/notification/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notificationId }),
      });
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setNotifications(prev => 
          prev.filter(notification => notification._id !== notificationId)
        );
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
    } finally {
      setDeletingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(notificationId);
        return newSet;
      });
      setNotifUpdated(true);
    }
  };

  const handleMarkRead = async (notificationId) => {
    const userId = localStorage.getItem('id');
    if (!userId) return;
    setMarkingReadIds(prev => new Set([...prev, notificationId]));
    
    try {
      const response = await fetch('https://api.aiflowpro.io/api/notification/mark-read', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notificationId, userId }),
      });
      
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setNotifications(prev =>
          prev.map(notification =>
            notification._id === notificationId
              ? { ...notification, isRead: true }
              : notification
          )
        );
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    } finally {
      setMarkingReadIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(notificationId);
        return newSet;
      });
      setNotifUpdated(true);
    }
  };

  const handleMarkAllRead = async () => {
    const userId = localStorage.getItem('id');
    if (!userId) return;
    setMarkingAllRead(true);
    
    try {
      const response = await fetch('https://api.aiflowpro.io/api/notification/mark-all-read', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setNotifications(prev =>
          prev.map(notification => ({ ...notification, isRead: true }))
        );
      }
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    } finally {
      setMarkingAllRead(false);
      setNotifUpdated(true);
    }
  };

  const getTimeAgo = (createdAt) => {
    return moment(createdAt).fromNow();
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedNotification(null);
  };

  const handleModalOpen = (notification) => {
    setSelectedNotification(notification);
    setModalOpen(true);
  };

  if (loading) {
    return (
      <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <>
      <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
        <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }} onClick={() => navigate("/")}>
            <img src={BackIcon} alt="back" />
            <Heading>Notifications ({notifications.length})</Heading>
          </div>
          {markingAllRead ? (
            <CircularProgress size={20} />
          ) : (
            <MarkText onClick={handleMarkAllRead}>Mark all as read</MarkText>
          )}
        </Header>
        <Content>
          {notifications.map((notification) => (
            <NotificationItem key={notification._id} isRead={notification.isRead} >
              <NotifImg src={RedCircle} alt="unread" isRead={notification.isRead}/>
              <img src={NotifIcon} alt="notification" />
              <TextDiv onClick={() => handleModalOpen(notification)}>
                <Title>{notification.title}</Title>
                <Desc>From {notification.sender}</Desc>
              </TextDiv>
              <StatusDiv>
                <div style={{display: "flex", gap: "0.5rem", marginLeft: "auto"}}>
                  {deletingIds.has(notification._id) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <img src={DeleteIcon} alt="delete" style={{cursor: "pointer"}} onClick={() => handleDelete(notification._id)}/>
                  )}
                  {markingReadIds.has(notification._id) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <img src={notification.isRead ? ReadIcon : UnreadIcon} alt="status" style={{cursor: "pointer"}} onClick={() => !notification.isRead && handleMarkRead(notification._id)}/>
                  )}
                </div>
                <Timestamp>{getTimeAgo(notification.createdAt)}</Timestamp>
              </StatusDiv>
            </NotificationItem>
          ))}
        </Content>
      </Container>
      {isModalOpen && selectedNotification && ( <NotificationModal notification={selectedNotification} onClose={handleModalClose} />
      )}
    </>
  );
};

export default NotificationPage;