import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  PaginationDiv,
  PaginationText,
  CurentPage,
  ToggleImage,
} from "./Style";
import NextIcon from "../../assets/next-icon.svg";
import PrevIcon from "../../assets/previous-icon.svg";

// This line is important for using workerSrc property to resolve the worker file correctly
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        style={{ width: "10rem" }}
      >
        <Page pageNumber={pageNumber} scale={0.9} />{" "}
        {/* Ensure the width fits within the max-width of RightPane */}
      </Document>
      <PaginationDiv>
        <ToggleImage
          src={PrevIcon}
          onClick={goToPrevPage}
          disabled={pageNumber <= 1}
          alt="Previous Page"
        />
        <PaginationText>Page</PaginationText>
        <CurentPage>
          <PaginationText>{pageNumber}</PaginationText>
        </CurentPage>
        <PaginationText>of {numPages}</PaginationText>
        <ToggleImage
          src={NextIcon}
          onClick={goToNextPage}
          disabled={pageNumber >= numPages}
          alt="Next Page"
        />
      </PaginationDiv>
    </>
  );
};

export default PdfViewer;
