import React, { useState, useRef, useEffect } from 'react';
import {
  PopupContainer,
  ChatWindow,
  Header,
  MessagesContainer,
  InputArea,
  InputWrapper,
  StyledInput,
  PopupIconImage,
  FileDiv,
  UploadDiv
} from './style';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { CircularProgress } from "@mui/material";
import ChatPopupMsg from './ChatPopupMsg';
import AvatarPopupDriveFilePicker from './AvatarPopupDriveFilePicker';
import PopupIcon from "../../assets/popup-icon.svg";
import Assistant from "../../assets/assistant-blue.svg";
import Clipper from "../../assets/paper-clip-small.svg";
import SendArrow from "../../assets/send-arrow.svg";
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-popup.svg";
import DocxIcon from "../../assets/doc-popup.svg";
import TxtIcon from "../../assets/txt-popup.svg";
import MoreIcon from "../../assets/more_vert-white.svg";
import ComputerIcon from "../../assets/computer-icon.svg";
import s3 from "../Environment/Asconfig";

const getFileTypeFromName = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  if (extension === 'pdf') return 'pdf';
  if (['doc', 'docx'].includes(extension)) return 'DOCX';
  if (extension === 'txt') return 'TXT';
  return 'unknown';
};

const formatFileFromUrl = (url) => {
  const fileName = decodeURIComponent(url.split('/').pop());
  return {
    name: fileName,
    type: getFileTypeFromName(fileName),
    url: url 
  };
};

const uploadFileToS3 = async (file) => {
  const selectedUserId = localStorage.getItem("id");
  const folderName = `${selectedUserId}/documents`;
  const key = `${folderName}/${file.name}`;
  const params = {
    Bucket: "aiflowpro-fe-userdata",
    Key: key,
    Body: file,
  };
  const data = await s3.upload(params).promise();
  return data.Location;
};

const formatTimestamp = (date) => {
  const offset = -date.getTimezoneOffset();
  const offsetSign = offset >= 0 ? "+" : "-";
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, "0");
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");

  return `${date.toISOString().slice(0, 19)}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

const ChatPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driveFiles, setDriveFiles] = useState([]);
  const [messages, setMessages] = useState([
    { id: 1, text: "👋 Hi there! How can I help?", isUser: false },
  ]);
  const [messageInput, setMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isClearingChat, setIsClearingChat] = useState(false);
  const bottomRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorFileEl, setAnchorFileEl] = useState(null);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClearChat = async () => {
    const userId = localStorage.getItem("id");
    setIsClearingChat(true);
    try {
      const response = await fetch("https://api.aiflowpro.io/api/auth/delete-avatar-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId
        }),
      });

      const data = await response.json();
      
      if (data.responseCode === 200) {
        setMessages([{ id: 1, text: "👋 Hi there! How can I help?", isUser: false }]);
      }
    } catch (error) {
      console.error("Error clearing chat history:", error);
    } finally {
      setIsClearingChat(false);
      handleClosePopover();
    }
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      const userId = localStorage.getItem("id");
      try {
        const response = await fetch(`https://api.aiflowpro.io/api/auth/avatar-chat?user_id=${userId}`);
        const data = await response.json();
        
        if (data.responseCode === 200) {
          const formattedMessages = data.avatarChats.flatMap((chat, index) => {
            const formattedFiles = chat.files.map(formatFileFromUrl);
            
            return [
              {
                id: index * 2 + 1,
                text: chat.query,
                isUser: true,
                files: formattedFiles
              },
              {
                id: index * 2 + 2,
                text: chat.content,
                isUser: false
              }
            ];
          });

          setMessages([
            { id: 0, text: "👋 Hi there! How can I help?", isUser: false },
            ...formattedMessages
          ]);
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatHistory();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const scrollTimeout = setTimeout(() => {
        scrollToBottom();
      }, 100);
      return () => clearTimeout(scrollTimeout);
    }
  }, [messages, isLoading]);

  useEffect(() => {
    if (isOpen && !isLoading) {
      scrollToBottom();
    }
  }, [isOpen, isLoading]);

  const handleFileUpload = (e) => {
    handleCloseFilePopover();
    const files = Array.from(e.target.files).map(file => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false
    }));
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const pop_id = open ? 'simple-popover' : undefined;

  const handleOpenFilePopover = (event) => {
    setAnchorFileEl(event.currentTarget);
  };

  const handleCloseFilePopover = () => {
    setAnchorFileEl(null);
  };

  const openFilePopover = Boolean(anchorFileEl);
  const filePop_id = openFilePopover ? 'simple-popover' : undefined;
  
  const handlePopupDriveFiles = async (fileLinks) => {
    handleClosePopover();
    const apiKey = process.env.REACT_APP_API_KEY;
    
    const newDriveFiles = fileLinks.map(fileId => {
      const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${apiKey}`;
      return {
        name: `Drive File ${fileId}`, // This will be updated when metadata is fetched
        type: 'application/octet-stream', // Default type
        isDrive: true,
        driveUrl: fileUrl,
        id: fileId
      };
    });
    try {
      const updatedFiles = await Promise.all(newDriveFiles.map(async (file) => {
        const metadataUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?fields=name,mimeType&key=${apiKey}`;
        const response = await fetch(metadataUrl);
        const metadata = await response.json();
        
        return {
          ...file,
          name: metadata.name,
          type: metadata.mimeType
        };
      }));
      setUploadedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    } catch (error) {
      console.error("Error fetching Drive file metadata:", error);
    }
  };
  const handleSendMessage = async (messageContent, files) => {
    const userMessage = {
      role: "human",
      content: messageContent,
      files: files
    };

    const user_id = localStorage.getItem("id") || "";
    const user_name = localStorage.getItem("name") || "";

    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 1, text: messageContent, isUser: true, files: files },
    ]);

    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 2, text: "Loading...", isUser: false },
    ]);

    const fileUrls = await Promise.all(files.map(async (file) => {
      if (file.isDrive) {
        return file.driveUrl;
      } else {
        return uploadFileToS3(file.file);
      }
    }));

    try {
      const assistantResponse = await fetch("https://assistant-api.aiflowpro.io/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          user_name,
          messages: [
            ...messages.map((msg) => ({
              role: msg.isUser ? "human" : "ai",
              content: msg.text,
            })),
            userMessage,
          ],
          timestamp: formatTimestamp(new Date()),
          s3_document_urls: fileUrls,
        }),
      });
      const assistantData = await assistantResponse.json();

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.text === "Loading..." ? { ...msg, text: assistantData.content, isUser: false } : msg
        )
      );

      await fetch("https://api.aiflowpro.io/api/auth/avatar-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          query: messageContent,
          content: assistantData.content,
          files: fileUrls
        }),
      });

    } catch (error) {
      console.error("Error handling message:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.text === "Loading..." ? { ...msg, text: "Error: Could not fetch response.", isUser: false } : msg
        )
      );
    }
  };

  const sendMessage = async () => {
    if (!messageInput.trim()) return;
    const currentUploadedFiles = [...uploadedFiles];
    const currentInputValue = messageInput;
    setMessageInput("");
    setUploadedFiles([]);
    await handleSendMessage(currentInputValue.trim(), currentUploadedFiles);
  };

  return (
    <PopupContainer>
      {isOpen && (
        <ChatWindow>
          <Header>
            <img src={Assistant} alt="assistant" />
            Chat Assistant
            <img 
              src={MoreIcon} 
              alt="more" 
              style={{marginLeft: "auto", cursor: "pointer"}} 
              onClick={handleOpenPopover}
            />
            <Popover
              id={pop_id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {isClearingChat ? (
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  padding: '16px',
                  width: '140px', // Match the width of the Typography component
                }}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <Typography 
                  sx={{ 
                    p: 2,
                    '&:hover': {
                      backgroundColor: '#F1F1F5',
                    },
                    cursor: 'pointer',
                    width: '140px', // Fixed width to prevent layout shift
                  }}
                  onClick={handleClearChat}
                >
                  Clear Chat
                </Typography>
              )}
            </Popover>
          </Header>
          <MessagesContainer ref={messagesContainerRef}>
            {messages.map((message) => (
              <ChatPopupMsg 
                key={message.id} 
                isUser={message.isUser} 
                message={message.text} 
                files={message.files || []}
              />
            ))}
            <div ref={bottomRef} style={{ float: "left", clear: "both" }} />
          </MessagesContainer>
          <InputArea>
            <FileDiv style={{ display: uploadedFiles.length > 0 ? "flex" : "none" }}>
              {uploadedFiles.map((file, index) => (
                <FileCard
                  key={index}
                  fileName={file.name}
                  fileType={file.type.includes("pdf") ? "pdf" : file.type.includes("word") ? "DOCX" : "TXT"}
                  fileIcon={
                    file.type.includes("pdf") ? PdfIcon : file.type.includes("word") ? DocxIcon : TxtIcon
                  }
                  onRemove={() => removeFile(file.name)}
                  isPopup={true}
                />
              ))}
            </FileDiv>
            <InputWrapper>
              <input 
                type="file" 
                multiple 
                accept=".pdf,.doc,.docx,.txt" 
                style={{ display: "none" }} 
                id="fileInput" 
                onChange={handleFileUpload} 
              />
              <img src={Clipper} alt="attach file" onClick={handleOpenFilePopover} style={{cursor: "pointer"}}/>
              <Popover
                id={filePop_id}
                open={openFilePopover}
                anchorEl={anchorFileEl}
                onClose={handleCloseFilePopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: "#f1f1f5"
                }}>
                  <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                    <UploadDiv>
                      <img src={ComputerIcon} alt="computer" /> Upload from computer
                    </UploadDiv>
                  </label>
                  <AvatarPopupDriveFilePicker onFilesSelected={handlePopupDriveFiles} />
                </div>
              </Popover>
              <StyledInput
                type="text"
                placeholder="Type a message..."
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && sendMessage()}
              />
              <img 
                src={SendArrow} 
                alt="send msg" 
                style={{ cursor: "pointer" }} 
                onClick={sendMessage}
              />
            </InputWrapper>
          </InputArea>
        </ChatWindow>
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: "flex-end", cursor: "pointer" }}>
        <PopupIconImage 
          onClick={() => setIsOpen(!isOpen)} 
          src={PopupIcon} 
          alt="Chat" 
          isOpen={isOpen} 
        />
      </div>
    </PopupContainer>
  );
};

export default ChatPopup;