import styled from "styled-components";

export const MarkText = styled.div`
  color: #696974;
  text-align: right;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; 
  &:hover {
    text-decoration: underline; /* Underline on hover */
  }
`;

export const Content = styled.div`
  height: 88vh;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.87rem;
  padding: 1.5rem;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);

  @media (max-height: 850px) {
    height: 100%;
    min-height: 80vh;
    max-height: 80vh;
  }
`;

export const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.625rem;
  background-color: ${(props) => (props.isRead ? "#FFF" : "#F1F1F5")};
  border-radius: 0.625rem;
  backdrop-filter: blur(40px);
`;

export const NotifImg = styled.img`
  opacity: ${(props) => (props.isRead ? "0" : "1")};
`;

export const TextDiv = styled.div`
  flex: 1;
  cursor: pointer;
`;

export const Title = styled.div`
  color: #171725;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: -0.03125rem;

  @media (max-width: 360px) {
    font-size: 0.8rem;
  }
`;

export const Desc = styled.div`
  color: #696974;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
  @media (max-width: 360px) {
    font-size: 0.6rem;
  }
`;

export const StatusDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const Timestamp = styled.div`
  color: #696974;
  text-align: right;
  font-family: Inter;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 138.462% */
  letter-spacing: -0.00488rem;
  @media (max-width: 360px) {
    font-size: 0.6rem;
  }
`;
