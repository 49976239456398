import React from "react";
import { ModalOverlay, Content, Header, ModalButton } from "./Style";

const RemovePdfModal = ({ onCancel, onRemove }) => {
  return (
    <ModalOverlay>
      <Content style={{ width: "35rem", alignItems: "center" }}>
        <Header>Are you sure you want to Remove this File?</Header>
        <div style={{ display: "flex", gap: "1rem" }}>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          <ModalButton
            style={{ background: "#1674FE", color: "#FFF" }}
            onClick={onRemove}
          >
            Remove
          </ModalButton>
        </div>
      </Content>
    </ModalOverlay>
  );
};

export default RemovePdfModal;