import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
  BillingSection,
  CancelButton,
  CardText,
  MainContainer,
  SubText,
  Subheader,
  SubscriptionCard,
  Title,
  CardInfo,
  RowDiv,
  RowText,
  DownloadButton,
  BillingButton
} from "./style";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import { CardImgs } from "../../Components/Constants/Constants";
import AddCardWrapper from "../../Components/modals/AddCardModal";
const Billing = () => {
  const { isSidebarOpen } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [planName, setPlanName] = useState("");
  const [card, setCard] = useState([]);
  const [cardBrandImg, setCardBrandImg] = useState("");
  const [cardExpiryYear, setCardExpiryYear] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [nextInvoiceDate, setNextInvoiceDate] = useState("");
  const [periodEnd, setPeriodEnd] = useState(""); // State for period end date
  const [invoices, setInvoices] = useState([]);
  const [subsCancelsAt, setSubsCancelsAt] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showCardModal, setShowCardModal] = useState(false);
  const navigate = useNavigate();

  const fetchCardDetails = async () => {
    const email = localStorage.getItem("email");
    try {
      const response = await fetch(`https://api.aiflowpro.io/api/auth/get-card?email=${email}`);
      const data = await response.json();

      if (data.responseCode === 2000) {
        setCard(data.response);
        setCardBrand(data.response.cardBrand.toLowerCase());
        setCardBrandImg(CardImgs[data.response.cardBrand.toLowerCase()]);
        setCardExpiryYear(data.response.cardExpiryYear);
      } else {
        console.error("Failed to retrieve card details:", data.message);
      }
    } catch (error) {
      console.error("Error fetching card details:", error);
    }
  };

  useEffect(() => {
    const packageID = localStorage.getItem("packageID");
    if (packageID === "1") {
      setPlanName("Monthly Plan");
    } else if (packageID === "2") {
      setPlanName("Yearly Plan");
    } else {
      setPlanName("Pro Annual");
    }

    const nextInvoice = localStorage.getItem("nextInvoice");
    if (nextInvoice) {
      const date = new Date(nextInvoice);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
      setNextInvoiceDate(`${day} ${month}, ${year}`);
    }

    const cancelsAt = localStorage.getItem("subsCancelsAt");
    if (cancelsAt) {
      const cancelDate = new Date(cancelsAt);
      const day = cancelDate.getDate();
      const month = cancelDate.toLocaleString("default", { month: "short" });
      const year = cancelDate.getFullYear();
      setPeriodEnd(`${day} ${month}, ${year}`);
      setSubsCancelsAt(cancelsAt);
    }

    const fetchInvoices = async () => {
      const email = localStorage.getItem("email");
      try {
        const response = await fetch(
          `https://api.aiflowpro.io/api/auth/getUserInvoices?email=${email}`
        );
        const data = await response.json();
        if (data.responseCode === 2000) {
          setInvoices(data.response);
        } else {
          console.error("Failed to fetch invoices:", data.message);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setIsLoadingData(false); // Stop loading once data is fetched
      }
    };
    fetchCardDetails();
    fetchInvoices();
  }, []);

  const handleCancelSubscription = async () => {
    setLoading(true); // Start loading
    const stripeCustomerId = localStorage.getItem("stripeCustomerId");
    if (!stripeCustomerId) {
      console.error("Stripe Customer ID not found");
      setSnackbarMessage("Error canceling subscription");
      setSnackbarOpen(true);
      setLoading(false); // Stop loading
      return;
    }

    try {
      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/cancel-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ stripeCustomerId }),
        }
      );
      const data = await response.json();

      if (data.responseCode === 2000) {
        if (data.response.status === "active") {
          console.log("Subscription canceled successfully");
          setSnackbarMessage("Subscription canceled successfully");
          setSnackbarOpen(true);
          // Set periodEnd with the cancel_at date from the response
          const cancelDate = new Date(data.response.cancel_at * 1000);
          const day = cancelDate.getDate();
          const month = cancelDate.toLocaleString("default", { month: "short" });
          const year = cancelDate.getFullYear();
          setPeriodEnd(`${day} ${month}, ${year}`);
          localStorage.setItem("subsCancelsAt", data.response.cancel_at * 1000);
          setSubsCancelsAt(data.response.cancel_at * 1000);
        } else if (data.response.status === "canceled") {
          localStorage.clear();
          navigate("/login");
        }
      } else {
        console.error("Failed to cancel subscription:", data.message);
        setSnackbarMessage("Error canceling subscription");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      setSnackbarMessage("Error canceling subscription");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDownloadInvoice = (url) => {
    window.open(url, "_blank");
  };

  const handleChangeCard = () => {
    navigate("/change-card"); 
  };

  const handleshowCardModal = () => {
    setShowCardModal(true);
  };

  const handleHideCardModal = () => {
    setShowCardModal(false);
  };

  return (
    <>
      <MainContainer isOpen={isSidebarOpen}>
        {isLoadingData ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress />
          </div>
        ) : (
        <>
          <Title>Billing</Title>
          <div style={{ display: "flex", marginTop: "2.06rem", gap: "2rem", marginBottom: '2.06rem'}}>
            <BillingSection>
              <Subheader>Order History</Subheader>
              <Subheader style={{marginTop: "0.5rem", color: "#696974", fontSize: "0.875rem", fontWeight: "500",}}>
                Manage billing information and view receipts.
              </Subheader>
              <div style={{ display: "flex", flexDirection: "column", marginTop: "1.5rem", overflowX: "auto",}}>
                <div style={{ display: "flex", marginBottom: "1.25rem", paddingLeft: '2rem', paddingRight: '2rem' }}>
                  <Subheader style={{ flex: "2" }}>Date</Subheader>
                  <Subheader style={{ flex: "3" }}>Type</Subheader>
                  <Subheader>Receipt</Subheader>
                </div>
                {invoices.map((invoice) => (
                  <RowDiv key={invoice.index}>
                    <RowText style={{ flex: "2" }}>
                      {new Date(invoice.createdDate).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </RowText>
                    <RowText style={{ flex: "3" }}>{invoice.details}</RowText>
                    <DownloadButton style={{ marginLeft: 'auto' }} onClick={() => handleDownloadInvoice(invoice.hostedInvoiceUrl)}>
                      <RowText style={{ lineHeight: "1rem" }}>Download</RowText>
                    </DownloadButton>
                  </RowDiv>
                ))}
              </div>
              <Subheader style={{ marginTop: "1.69rem" }}>Payment Method</Subheader>
              <div style={{ display: "flex", marginTop: "1.5rem", alignItems: "center", paddingRight: "1.25rem"}}>
                <img src={cardBrandImg} alt="card" style={{ width: "4.625rem", height: "2.8125rem" }}/>
                <CardInfo style={{ marginLeft: "1.25rem" }}>
                  {cardBrand} ending in {cardExpiryYear}
                </CardInfo>
                <BillingButton style={{ marginLeft: "auto" }} onClick={() => handleshowCardModal()}>Change</BillingButton>
              </div>
            </BillingSection>
            <div style={{ flex: "1" }}>
              <SubscriptionCard>
                <CardText>Your Plan</CardText>
                <CardText style={{ fontSize: "1.5rem", fontWeight: "600", marginTop: "1.35rem", }}>{planName}</CardText>
                <SubText style={{ marginTop: "0.38rem" }}>
                  {subsCancelsAt ? `Your subscription has been cancelled. Your current access will expire on ${periodEnd}` : `Renews on ${nextInvoiceDate}`}
                </SubText>
                {/* <CancelButton
                  style={{ marginTop: "1.69rem", cursor: "pointer" }}
                  onClick={subsCancelsAt ? handleSubscribeAgain : handleCancelSubscription}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} style={{ color: "#ffffff" }} />
                  ) : subsCancelsAt ? "Subscribe Again" : "Cancel Subscription"}
                </CancelButton> */}
                {!subsCancelsAt && (
                  <CancelButton style={{ marginTop: "1.69rem", cursor: "pointer" }} onClick={handleCancelSubscription} disabled={loading}>
                    {loading ? (
                      <CircularProgress size={24} style={{ color: "#ffffff" }} />
                    ) : "Cancel Subscription"}
                  </CancelButton>
                )}
              </SubscriptionCard>
            </div>
          </div>
          <SnackbarAlert open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage} 
            severity={snackbarMessage === "Subscription canceled successfully" ? "success" : "error" }
          />
        </>
        )}
      </MainContainer>
      {showCardModal && (
        <AddCardWrapper onClose={handleHideCardModal} card={card} onUpdate={fetchCardDetails}/>
      )}
    </>
  );
};

export default Billing;
