import React, { useState,useEffect, useRef } from "react";
import { SourceItem, SourceTxt, Popup, PopupItem } from "./style";
import PdfIcon from "../../assets/pdf.svg";
import DocxIcon from "../../assets/docx.svg";
import TxtIcon from "../../assets/txt.svg";
import WhiteCircle from "../../assets/white-circle.svg";
import DottedMenu from "../../assets/kebab-menu.svg";
import ChunkModal from "../modals/ChunkModal";

const SourceCard = ({ cardText, documentType, onClick, chunk = "", url = "" }) => {
  console.log("url", url);
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const handleshowDetailsModal = () => {
    setshowDetailsModal(true);
  };

  const handleHideDetailsModal = () => {
    setshowDetailsModal(false);
  };

  let displayImg;
  let backgroundColor;

  // Determine the icon and background color based on the document type
  switch (documentType) {
    case "pdf":
      displayImg = PdfIcon;
      backgroundColor = "#E5252A";
      break;
    case "doc":
    case "docx":
      displayImg = DocxIcon;
      backgroundColor = "#1757B7";
      break;
    case "txt":
      displayImg = TxtIcon;
      backgroundColor = "#2C93D4";
      break;
    default:
      displayImg = PdfIcon; // Default to PDF icon if type is unknown
      backgroundColor = "#E5252A";
      break;
  }

  // Truncate cardText if it exceeds 40 characters
  const truncatedCardText =
    cardText.length > 35 ? `${cardText.substring(0, 35)}...` : cardText;

  // Truncate chunk if it exceeds 15 characters
  // const truncatedChunk =
  //   chunk.length > 15 ? `${chunk.substring(0, 15)}...` : chunk;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
    <SourceItem style={{ backgroundColor }}>
      <img
        src={DottedMenu}
        alt="menu"
        style={{
          width: "1rem",
          height: "0.8rem",
          position: "absolute",
          right: "0.2rem",
          top: "0.5rem",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={() => setShowPopup(!showPopup)}
      />
      <Popup isOpen={showPopup} ref={popupRef}>
        <PopupItem>Open Document</PopupItem>
        <PopupItem onClick={onClick}>Talk to Document</PopupItem>
        <PopupItem onClick={() => handleshowDetailsModal()}>Details</PopupItem>
      </Popup>
      <SourceTxt>{truncatedCardText}</SourceTxt>
      {/* <SourceTxt style={{ fontSize: "10px" }} title={chunk}>
        {truncatedChunk}
      </SourceTxt> */}
      <div
        style={{
          display: "flex",
          gap: "0.19rem",
          marginTop: "auto",
          position: "relative",
        }}
      >
        <img src={WhiteCircle} alt="doctype" />
        <img
          src={displayImg}
          alt="document icon"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(8%, -49%)",
          }}
        />
      </div>
    </SourceItem>
    {showDetailsModal && (
      <ChunkModal onClose={handleHideDetailsModal} chunk={chunk}/>
    )}
    </>
  );
};

export default SourceCard;
