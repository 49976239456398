import React from 'react';
import { ModalOverlay, Content, ModalTxt } from "./Style";
import CloseX from "../../assets/x-close.svg";

const ChunkModal = ({ chunk, onClose }) => {
  console.log("chunk", chunk);
  
  return (
    <ModalOverlay>
      <Content style={{position: "relative", height:"40rem", overflowY: "auto", width: "30rem"}}>
        <img src={CloseX} alt="close" style={{position: "absolute", right: "1rem", cursor: "pointer"}} onClick={onClose}/>
        <ModalTxt
          style={{ textAlign: "left", fontSize: "0.9rem" }}
          dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, "<br/>") }}
        />
      </Content>
    </ModalOverlay>
  );
}

export default ChunkModal;
