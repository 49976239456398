import React from "react";
import PricingCard from "../../Components/Cards/PricingCard";
import {
  Container,
  ContentDiv,
  Heading,
  MainDiv,
  PlansDiv,
  SubHeading,
  TextDiv,
} from "./style";
import Logo from "../../assets/Logo.svg";
import { BottomRightText } from "../../Components/HeroSection/Style";

export default function Payment() {
  return (
    <>
      <Container>
        <img
          src={Logo}
          alt="logo"
          style={{
            width: "13.0625rem",
            height: "4.4375rem",
            margin: "1.88rem",
          }}
        />
        <MainDiv>
          <ContentDiv>
            <TextDiv>
              <Heading>Boost Your Efficiency with AI Flow Pro</Heading>
              <SubHeading>
              At AI Flow Pro, we empower you with cutting-edge AI applications designed to enhance productivity 
              and streamline your workflow. Whether you're an individual professional or part of a larger team, 
              our platform offers AI tools to help you achieve more in less time. Explore our subscription plans 
              and choose the one that best fits your needs.
              </SubHeading>
            </TextDiv>
            <PlansDiv>
              <PricingCard
                title="Monthly Plan"
                price="35"
                bgColor="#0062FF"
                textColor="#ffffff"
                buttonColor="#ffffff"
                buttonTextColor="#0062FF"
                status="/month"
                feature1="Seamless Integration: Easily incorporate AI tools into your existing workflow."
                feature2="24/7 Support: Access our dedicated support team anytime you need assistance."
                feature3="Regular Updates: Stay ahead with the latest AI advancements and features."
              />
              <PricingCard
                title="Yearly Plan"
                price="350"
                bgColor="#ffffff"
                textColor="#0F172A"
                buttonColor="#0062FF"
                buttonTextColor="#ffffff"
                status="/year"
                feature1="Exclusive Savings: Save €70 with our yearly subscription."
                feature2="Priority Access: Be the first to access new features and updates.."
                feature3="Enhanced Support: Enjoy premium support with faster response times."
              />
            </PlansDiv>
          </ContentDiv>
        </MainDiv>
        <BottomRightText>AI FLOW PRO</BottomRightText>
      </Container>
      
    </>
  );
}