import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Icon,
  PageTitle,
  PageDiv,
  Message,
  Button,
} from './Style';
import CrossIcon from '../../assets/cross-icon.svg'; // Adjust the path as necessary

const EmailConfirmationFailed = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/signup');
  };

  return (
    <PageDiv>
      <Icon src={CrossIcon} alt="Error Icon" />
      <PageTitle>Email Confirmation Failed</PageTitle>
      <Message>There was an issue confirming your email</Message>
      <Button onClick={handleContinue} style={{ marginTop: '1rem' }}>
        Continue to signup
      </Button>
    </PageDiv>
  );
};

export default EmailConfirmationFailed;
