import "./App.css";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Auth/Login";
import SignUp from "./Pages/Auth/Signup";
import ForgotPassword from "./Pages/Auth/ForgotPassowrd";
import PasswordSetup from "./Pages/Auth/PasswordSetup";
import ResetSuccess from "./Pages/Auth/ResetSuccess";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroSection from "./Components/HeroSection/HeroSection";
import Summarize from "./Components/Summarize/Summarize";
import EmailAutomation from "./Components/EmailAutomation/EmailAutomation";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import PaymentProtectedRoute from "./Components/Routes/PaymentProtectedRoute";
import UpdateProfile from "./Pages/Profile/UpdateProfile";
import Payment from "./Pages/Payment/Payment";
import PaymentSucess from "./Pages/Payment/Success";
import Billing from "./Pages/Payment/Billing";
import EmailConfirmationSuccess from "./Pages/Auth/EmailConfirmationSuccess";
import EmailConfirmationFailed from "./Pages/Auth/EmailConfirmationFailed";
import VerificationSuccess from "./Pages/Auth/VerificationSuccess";
import PaymentSuccessful from "./Pages/payment-sucess";
import PaymentFailure from "./Pages/PaymentFailure";
import TestEmail from "./Components/EmailAutomation/TestEmail";
import SummarizeDoc from "./Components/Summarize/SummarizeDoc";
import GeneralSearch from "./Components/Summarize/GeneralSearch";
import Transcriber from "./Components/Meetings/Transcriber";
import Forest from "./Components/ForestApp/Forest";
import Todo from "./Components/Todo/Todo";
import AvatarChat from "./Components/Avatar/AvatarChat";
import AvatarSelection from "./Pages/Avatar/AvatarSelection";
import AvatarInfo from "./Pages/Avatar/AvatarInfo";
import Skills from "./Components/Skills/Skills";
import AddSkills from "./Components/Skills/AddSkills";
import EditSkills from "./Components/Skills/EditSkills";
import RunSkills from "./Components/Skills/RunSkills";
import NotificationPage from "./Components/Notification/NotificationPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route index element={<HeroSection />} />
          <Route path="chat" element={<Summarize />} />
          <Route path="summarize" element={<SummarizeDoc />} />
          <Route path="generalsearch" element={<GeneralSearch />} />
          <Route path="transcriber" element={<Transcriber />} />
          <Route path="email" element={<EmailAutomation />} />
          <Route path="forest" element={<Forest />} />
          <Route path="todo" element={<Todo />} />
          <Route path="testemail" element={<TestEmail />} />
          <Route path="updateprofile" element={<UpdateProfile />} />
          <Route path="billing" element={<Billing />} />
          <Route path="avatar-chat" element={<AvatarChat />} />
          <Route path="notifications" element={<NotificationPage />} />
          <Route path="skills" element={<Skills />} />
          <Route path="add-skills" element={<AddSkills />} />
          <Route path="edit-skills/:id" element={<EditSkills />} />
          <Route path="run-skills/:id" element={<RunSkills />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/avatar-selection" element={<AvatarSelection />} />
        <Route path="/avatar-info" element={<AvatarInfo />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<PasswordSetup />} />
        <Route path="/resetsuccess" element={<ResetSuccess />} />
        <Route path="/emailconfirmed" element={<EmailConfirmationSuccess />} />
        <Route path="/emailnotcofirmed" element={<EmailConfirmationFailed />} />
        <Route path="/verificationsuccess" element={<VerificationSuccess />} />

        <Route
          path="/payment"
          element={
            <PaymentProtectedRoute>
              <Payment />
            </PaymentProtectedRoute>
          }
        />
        <Route
          path="/paymentsucess"
          element={
            <PaymentProtectedRoute>
              <PaymentSucess />
            </PaymentProtectedRoute>
          }
        />
        <Route
          path="/success"
          element={
            <PaymentProtectedRoute>
              <PaymentSuccessful />
            </PaymentProtectedRoute>
          }
        />
        <Route
          path="/failure"
          element={
            <PaymentProtectedRoute>
              <PaymentFailure />
            </PaymentProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
