import React, {useEffect} from 'react'
import Success from '../../assets/PaymentSuccess.svg';
import { useNavigate } from 'react-router-dom';
import StatusDisplay from '../../Components/StatusDisplay/StatusDisplay';

const PaymentSuccessful = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/avatar-selection');
    };
    
    useEffect(() => {
        const isSubscribed = localStorage.getItem('isSubscribed');
        if (isSubscribed !== null) {
          localStorage.setItem('isSubscribed', 'true');
        } else {
          localStorage.setItem('isSubscribed', 'true');
        }
    }, []);

    return (
        <StatusDisplay imgSrc={Success} title={'Payment Successful'} handleClick={handleLoginClick} text={'Thank you for your payment. 😊'} btnText={'Continue to Site'} />
    )
}

export default PaymentSuccessful