import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ModalOverlay,
  Content,
  Header,
  PasswordLabel,
  PasswordInput,
  PasswordDiv,
  ModalButton,
} from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../Alerts/SnackbarAlert";

const PasswordModal = ({ onCancel }) => {
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      setSnackbarMessage("Email not found in local storage");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, []);

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbarMessage("New password and confirm password do not match");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post("https://api.aiflowpro.io/api/auth/change-password", {
        email,
        currentPassword,
        newPassword
      });

      setSnackbarMessage(response.data.msg);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setLoading(false);
      setTimeout(() => {
        onCancel();
      }, 2000); // 1000 milliseconds = 1 second

       // Close the modal on success
    } catch (error) {
      setSnackbarMessage(error.response.data.msg || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <ModalOverlay>
      <Content style={{ width: "20rem", gap: "1.5rem" }}>
        <Header style={{ alignSelf: "stretch", textAlign: "center" }}>
          Change Password
        </Header>
        <PasswordDiv>
          <PasswordLabel>Current Password</PasswordLabel>
          <PasswordInput
            type="password"
            name="currentPassword"
            placeholder="Enter your current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </PasswordDiv>
        <PasswordDiv>
          <PasswordLabel>New Password</PasswordLabel>
          <PasswordInput
            type="password"
            name="newPassword"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </PasswordDiv>
        <PasswordDiv>
          <PasswordLabel>Confirm Password</PasswordLabel>
          <PasswordInput
            type="password"
            name="confirmPassword"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </PasswordDiv>
        <div style={{ display: "flex", gap: "1rem", marginLeft: "auto" }}>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          <ModalButton
            style={{ background: "#1674FE", color: "#FFF", display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} style={{ color: "#FFF" }} /> : "Submit"}
          </ModalButton>
        </div>
      </Content>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  );
};

export default PasswordModal;
