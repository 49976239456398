import React from "react";
import StatusDisplay from "../../Components/StatusDisplay/StatusDisplay";
import Failure from "../../assets/PaymentFailure.svg";
import { useNavigate } from "react-router-dom";

const PaymentFailure = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/payment");
  };

  return (
    <StatusDisplay
      imgSrc={Failure}
      title={"Payment Failed"}
      handleClick={handleLoginClick}
      text={`We're sorry, but your payment could not be processed.`}
      btnText={"Back to Payment"}
    />
  );
};
export default PaymentFailure;
