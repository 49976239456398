import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Icon,
  PageTitle,
  PageDiv,
  Message,
  Button,
} from './style';
import TickIcon from '../../assets/tick-icon.svg'; // Adjust the path as necessary

const PaymentSuccess = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
      navigate('/');
    };
  return (
    <PageDiv>
      <Icon src={TickIcon} alt="Success Icon" />
      <PageTitle>Subscription Successful</PageTitle>
      <Message>You have been subscribed successfully</Message>
      {/* <SubMessage>You'll receive a confirmation email shortly.</SubMessage> */}
      <Button onClick={handleContinue}>Continue to site</Button>
    </PageDiv>
  );
};

export default PaymentSuccess;
