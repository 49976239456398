import React from 'react';
import { MessageContainer, MessageBubble, LoadingText, ChatFileDiv } from './style';
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";

const formatContent = (content) => {
  content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  content = content.replace(/(\d+)\. \*\*(.*?)\*\*/g, '<li><strong>$2</strong></li>');
  content = content.replace(/##{1,2} (.*?)\n/g, (_, text) => `<h2 style="margin: 0.75rem 0rem;font-size:16px;">${text}</h2>`);
  content = content.replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>');
  content = content.split('\n').map(line => `<p style="margin: 0rem 0rem;">${line}</p>`).join('');
  content = content.replace(/#/g, '');
  return content;
};

const ChatMessage = ({ text, isUser, avatarImage, userColor, userTextColor, avatarColor, avatarTextColor, files = [] }) => {
  const getFileIcon = (fileType) => {
    if (fileType?.includes("pdf")) return PdfIcon;
    if (fileType?.includes("word") || fileType?.includes("docx")) return DocxIcon;
    return TxtIcon;
  };

  const getFileType = (fileType) => {
    if (fileType?.includes("pdf")) return "PDF";
    if (fileType?.includes("word") || fileType?.includes("docx")) return "DOCX";
    return "TXT";
  };

  return (
    <div>
      {isUser && files.length > 0 && (
        <ChatFileDiv>
          {files.map((file, index) => (
            <FileCard key={index} fileName={file.name} fileType={getFileType(file.type)} fileIcon={getFileIcon(file.type)} isPopup={false} hideCloseButton={true} />
          ))}
        </ChatFileDiv>
      )}
      <MessageContainer isUser={isUser}>
        {!isUser && <img src={avatarImage} alt="Avatar" style={{ width: "2.5rem", height: "2.5rem", borderRadius: "60%", background: "#FFF" }}/>}
        <MessageBubble bgColor={isUser ? userColor : avatarColor} textColor={isUser ? userTextColor : avatarTextColor}>
          {text === "Loading..." ? <LoadingText /> : <span dangerouslySetInnerHTML={{ __html: formatContent(text) }} />}
        </MessageBubble>
      </MessageContainer>
    </div>
  );
};

export default ChatMessage;
