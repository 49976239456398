// VerificationModal.jsx
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import Email from "../../assets/Email.svg";
import StatusDisplay from "../../Components/StatusDisplay/StatusDisplay";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import CircularProgress from '@mui/material/CircularProgress';

const modalStyle = {
  overlay: {
    zIndex: 1002,
    backdropFilter: "blur(3px)",
    WebkitBackdropFilter: "blur(3px)",
    background: "none",
  },
  content: {
    border: "none",
    background: "transparent",
    inset: "0px",
    padding: "20px 1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const VerificationModal = ({ modalIsOpen, closeModal, email }) => {
  const [loading, setLoading] = useState(false); 
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleResendEmail = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/resend-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error("Resend email failed");
      }

      const data = await response.json();
      console.log("Resend email successful:", data.msg);
      setSnackbarMsg("Email Resent");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Resend email failed:", error);
      setSnackbarMsg("Couldn't Resend Email");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    finally {
      setLoading(false); // Set loading to false when the API call is complete
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("popup-card");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        shouldCloseOnOverlayClick={true}
      >
        <StatusDisplay
          bs="20px 30px 40px 20px rgba(220, 220, 220, 0.2)"
          imgSrc={Email}
          title={"Verify your Email"}
          handleClick={handleResendEmail}
          text={"We have sent a verification link to your email."}
          btnText={loading ? <CircularProgress size={24} /> : 'Resend'}
          width="50%"
          bg="transparent"
          height="50%"
          innerWidth="60%"
        />
      </ReactModal>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMsg}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default VerificationModal;
