import React, { useEffect } from "react";
import ReactModal from "react-modal";
import StatusDisplay from "../../Components/StatusDisplay/StatusDisplay";
import NotVerified from "../../assets/email-verification-failure.svg";

const modalStyle = {
  overlay: {
    zIndex: 1002,
    backdropFilter: "blur(3px)",
    WebkitBackdropFilter: "blur(3px)",
    background: "none",
  },
  content: {
    border: "none",
    background: "transparent",
    inset: "0px",
    padding: "20px 1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const SendEmailModal = ({ modalIsOpen, closeModal, handleResendEmail }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("popup-card");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyle}
      shouldCloseOnOverlayClick={true}
    >
      <StatusDisplay
        bs="20px 30px 40px 20px rgba(220, 220, 220, 0.2)"
        imgSrc={NotVerified}
        title={"Email not Verified"}
        handleClick={handleResendEmail} // Pass the handleResendEmail function here
        text={"Verify your email by clicking the button below."}
        btnText={"Send Verification Email"}
        width="50%"
        bg="transparent"
        height="50%"
        innerWidth="60%"
      />
    </ReactModal>
  );
};

export default SendEmailModal;
