import React, { useState } from "react";
import { FileBox, FileName, FileType, ImageDiv } from "./style";
import closeX from "../../assets/cancel.png";

const FileCard = ({ fileName, fileType, fileIcon, onRemove, isPopup, hideCloseButton = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <FileBox onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} isPopup={isPopup}>
      {isHovered && !hideCloseButton && (
        <img src={closeX} alt="remove" style={{ position: "absolute", top: "1px", right: "1px", cursor: "pointer" }} onClick={onRemove} />
      )}
      <ImageDiv isPopup={isPopup}>
        <img src={fileIcon} alt="file type icon" />
      </ImageDiv>
      <div style={{ display: "flex", flexDirection: "column", }}>
        <FileName>{fileName}</FileName>
        <FileType isPopup={isPopup}>{fileType.toUpperCase()}</FileType>
      </div>
    </FileBox>
  );
};

export default FileCard;
