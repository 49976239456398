import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { ModalOverlay, Content, Header, ModalButton, ModalTxt, CancelBtn } from "./Style";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";

const RemoveFileModal = ({ onClose, file, currFileUrls, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [remainingFileUrls, setRemainingFileUrls] = useState([]);

  useEffect(() => {
    // Calculate remainingFileUrls by filtering out the URLs of the files to be deleted
    const fileUrlsToDelete = file.map(f => f.fileUrl);
    setRemainingFileUrls(currFileUrls.filter(url => !fileUrlsToDelete.includes(url)));
  }, [file, currFileUrls]);

  const handleRemove = async () => {
    console.log("rem", remainingFileUrls);
  
    setIsLoading(true); // Start loading state
    const userId = localStorage.getItem("id");
    const fileIdsToDelete = file.map(f => f._id);
  
    console.log("to del", fileIdsToDelete);
    try {
      // Step 1: Call delete-file API
      const deleteFileResponse = await axios.delete(
        "https://api.aiflowpro.io/api/auth/delete-file",
        {
          data: {
            user_id: userId,
            file_ids: fileIdsToDelete,
          },
        }
      );
  
      if (deleteFileResponse.data.responseCode === 200) {  
        // Step 2: Update training status to "pending"
        const trainingStatusResponse = await axios.post(
          "https://api.aiflowpro.io/api/auth/set-training-status",
          {
            user_id: userId,
            isTrained: false,
            trainingStatus: "pending",
          }
        );
  
        if (trainingStatusResponse.data.responseCode === 200) {
          // Step 3: Call create_embeddings_multidoc API with remainingFileUrls
          if (remainingFileUrls.length > 0) {
            const embeddingResponse = await axios.post(
              "https://flowproai.dialect-ai.com/ai_flow_pro/create_embeddings_multidoc",
              {
                user_id: userId,
                uploaded_urls: remainingFileUrls,
              }
            );
            
            if (
              embeddingResponse.data.message ===
              "Embeddings are being created in the background."
            ) {
              setSnackbarMessage("Files deleted, Remaining files being trained");
              setSnackbarSeverity("success");
              setSnackbarOpen(true);
              onUpdate();
            } else {
              setSnackbarMessage("Failed to create embedding for remaining files");
              setSnackbarSeverity("error");
              setSnackbarOpen(true);
            }
          } else {
            console.log("No remaining files to train.");
            onUpdate();
          }
        } else {
          setSnackbarMessage("Failed to update training status");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } else {
        console.error("Failed to delete files");
        setSnackbarMessage("Failed to delete files");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error during the remove operation", error);
      setSnackbarMessage("An error occurred during deletion");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false); // End loading state
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose();
      }, 1000); // Close the modal after the operation
    }
  };

  return (
    <ModalOverlay>
      <Content style={{ width: "35rem", alignItems: "center" }}>
        <Header>Are you sure?</Header>
        <ModalTxt>Deleting these files will train the model again</ModalTxt>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <CancelBtn onClick={onClose}>Cancel</CancelBtn>
          <ModalButton
            style={{
              background: "#1674FE",
              color: "#FFF",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleRemove}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Remove"
            )}
          </ModalButton>
        </div>
      </Content>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  );
};

export default RemoveFileModal;
