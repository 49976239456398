import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  LoginBox,
  Title,
  Input,
  FormText,
  SignInButton,
  ContentDiv,
} from "./Style";
import axios from "axios";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import { CircularProgress } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.aiflowpro.io/api/auth/forgot-password-email",
        { email }
      );
      setSnackbarMessage(response.data.msg);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to send password reset email");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <LoginBox>
        <ContentDiv>
          <Title>Forgot Password</Title>
          <Form>
            <Input
              type="email"
              placeholder="Please enter you email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <SignInButton type="submit" disabled={loading} onClick={handleSubmit}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </SignInButton>
          </Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormText
              style={{ marginTop: "1.81rem", cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Back
            </FormText>
          </div>
        </ContentDiv>
      </LoginBox>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
}
