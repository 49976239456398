import React, { useState, useEffect } from "react";
import { Button, Flex, Table, Dropdown } from "antd";
import DropdownMenu from "./DropdownMenu";
import { EllipsisOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SkillsTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchSkills = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("id");
      const res = await axios.get(
        "https://api.aiflowpro.io/api/skill/user/" + userId
      );
      setData(res.data.skills);
    } catch (error) {
      console.error("Error fetching skills:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);

      // Get skill name before deletion for notification message
      const skillToDelete = data.find((skill) => skill._id === id);
      const skillName = skillToDelete?.name || "Skill";

      // Delete the skill
      await axios.delete(`https://api.aiflowpro.io/api/skill/${id}/`);

      // Send success notification
      await axios.post("https://api.aiflowpro.io/api/notification", {
        recipientId: localStorage.getItem("id"),
        senderId: "Skills App",
        title: "Skill Deleted Successfully",
        content: `"${skillName}" has been deleted successfully.`,
        urgency: "normal",
      });

      fetchSkills();
    } catch (error) {
      console.error("Error deleting skill:", error);

      // Send failure notification
      await axios.post("https://api.aiflowpro.io/api/notification", {
        recipientId: localStorage.getItem("id"),
        senderId: "Skills App",
        title: "Skill Deletion Failed",
        content: "Failed to delete the skill. Please try again.",
        urgency: "normal",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRunSkill = async (skill) => {
    try {
      const skillData = data.find((item) => item._id === skill.key);

      if (!skillData) {
        console.error("Skill data not found");
        return;
      }

      const payload = {
        user_id: skillData.user._id,
        skill_id: skillData._id,
        user_name: skillData.user.name,
        objectives: skillData.objectives,
        task_guidelines: skillData.taskGuidelines,
        required_output: skillData.requiredOutput,
        other_rules: skillData.otherRules,
        query: skillData.query,
        // uploaded_urls: skillData.files.map((file) => file.fileUrl),
      };

      const response = await axios.post(
        "https://flowproai.dialect-ai.com/ai_flow_pro/run_skill",
        payload
      );

      console.log("Skill run response:", response.data);
    } catch (error) {
      console.error("Error running skill:", error);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Objectives",
      dataIndex: "objectives",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Task Guidelines",
      dataIndex: "taskGuidelines",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Sample Input",
      dataIndex: "sampleInput",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Required Output",
      dataIndex: "requiredOutput",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Files",
      dataIndex: "files",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Other Rules",
      dataIndex: "otherRules",
      width: "12.5%",
      ellipsis: true,
    },
    {
      title: "Model",
      dataIndex: "model",
      width: "12.5%",
      ellipsis: true,
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (_, skill) => (
        <Dropdown
          overlay={
            <DropdownMenu
              onDelete={handleDelete}
              onEdit={() => {
                navigate(`/edit-skills/${skill.key}`);
              }}
              onRunSkill={async () => {
                // navigate(`/run-skills/${skill.key}`);
                handleRunSkill(skill);
              }}
              skill={skill}
            />
          }
          trigger={["click"]}
        >
          <EllipsisOutlined className="step-4" style={{ fontSize: "20px" }} />
        </Dropdown>
      ),
    },
  ];

  const dataSource = data.map((item) => ({
    key: item._id,
    name: item.name,
    objectives: item.objectives,
    taskGuidelines: item.taskGuidelines,
    sampleInput: item.sampleInput,
    requiredOutput: item.requiredOutput,
    files: item.files.map((file) => file.fileName).join(", "),
    otherRules: item.otherRules,
    model: item.model,
  }));

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Flex gap="middle" vertical>
        <Flex align="center" gap="middle">
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
        </Flex>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={loading ? [] : dataSource}
          loading={loading}
          locale={{
            emptyText: loading ? "Loading data..." : "No data available",
          }}
        />
      </Flex>
    </>
  );
};

export default SkillsTable;
