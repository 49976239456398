import React, { useState, useRef, useEffect } from "react";
import {
  ModalOverlay,
  UploadContent,
  UploadTxt,
  ModalBtn,
  DragAndDrop,
  Icon,
  UploadText,
  SupportText,
  FilesDiv,
} from "./Style";
import CloseX from "../../assets/x-close.svg";
import UploadIcon from "../../assets/upload.svg";
import BlueCross from "../../assets/blue-cross.svg";
import FileIcon from "../../assets/file-icon.svg";

import axios from "axios";
import { CircularProgress } from "@mui/material";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes
const MAX_TOTAL_SIZE_MB = 200; // 200MB

const UploadModal = ({ onClose, currFileUrls, onUpdate, totalFileSizeMB }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const dragDropRef = useRef(null);

  // Calculate the total size of selected files in MB
  const calculateSelectedFilesSizeMB = (files) => {
    return files.reduce((total, file) => total + file.size, 0) / 1024 / 1024;
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => {
      const newFileName = file.name.replace(/\s+/g, "-");
      return new File([file], newFileName, { type: file.type });
    });

    const largeFile = files.find(file => file.size > MAX_FILE_SIZE);
    if (largeFile) {
      setSnackbarMessage("File size exceeds 50MB");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      const newTotalSizeMB = calculateSelectedFilesSizeMB([...selectedFiles, ...files]) + parseFloat(totalFileSizeMB);
      if (newTotalSizeMB > MAX_TOTAL_SIZE_MB) {
        setSnackbarMessage("Data exceeded 200MB. Delete some files to continue");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragDropRef.current.style.border = "2px solid #2F80ED";
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragDropRef.current.style.border = "2px dashed #cccccc";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragDropRef.current.style.border = "2px dashed #cccccc";

    const files = Array.from(event.dataTransfer.files).map((file) => {
      const newFileName = file.name.replace(/\s+/g, "-");
      return new File([file], newFileName, { type: file.type });
    });

    const largeFile = files.find(file => file.size > MAX_FILE_SIZE);
    if (largeFile) {
      setSnackbarMessage("File size exceeds 50MB");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      const newTotalSizeMB = calculateSelectedFilesSizeMB([...selectedFiles, ...files]) + parseFloat(totalFileSizeMB);
      if (newTotalSizeMB > MAX_TOTAL_SIZE_MB) {
        setSnackbarMessage("Data exceeded 200MB. Delete some files to continue");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
      }
    }
  };

  const handleRemoveFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = async () => {
    setLoading(true);
    const signedUrls = [];
    const id = localStorage.getItem("id");

    for (const file of selectedFiles) {
      try {
        const response = await axios.get("https://api.aiflowpro.io/api/auth/get-signed-url", {
          params: { userId: id, fileName: file.name },
        });

        if (response.data && response.data.signedUrl) {
          await axios.put(response.data.signedUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          signedUrls.push({
            fileUrl: response.data.key,
            fileName: file.name,
            fileSize: file.size,
            uploadTime: new Date().toISOString(),
          });
        }
      } catch (error) {
        console.error("Error uploading file", error);
      }
    }

    try {
      // Call set-training-status API
      await axios.post("https://api.aiflowpro.io/api/auth/set-training-status", {
        user_id: id,
        isTrained: false,
        trainingStatus: "pending",
      });

      // Prepare payload for the embeddings API
      const embeddingResponse = await axios.post("https://flowproai.dialect-ai.com/ai_flow_pro/create_embeddings_multidoc", {
        user_id: id,
        uploaded_urls: [
          ...currFileUrls, // Include currFileUrls
          ...signedUrls.map(file => file.fileUrl) // Include new file URLs
        ],
      });

      console.log("files", embeddingResponse);

      if (embeddingResponse.data.message === "Embeddings are being created in the background.") {
        // Call upload-files API to save file data
        await axios.post("https://api.aiflowpro.io/api/auth/upload-files", {
          user_id: id,
          files: signedUrls, // Only include newly uploaded files here
        });
      }
      onUpdate();
      setSnackbarMessage("File/s uploaded successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to upload files");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setLoading(false);
    setTimeout(() => {
      setSnackbarOpen(false);
      onClose();
    }, 2000);
  };

  return (
    <ModalOverlay>
      <UploadContent>
        <div style={{ display: "flex", width: "100%" }}>
          <UploadTxt>Upload your Data files</UploadTxt>
          <img
            src={CloseX}
            alt="close"
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
        <DragAndDrop
          ref={dragDropRef}
          onClick={() => document.getElementById("fileInput").click()}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            id="fileInput"
            type="file"
            accept=".pdf,.doc,.docx,.xlsx,.txt"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
          <Icon>
            <img src={UploadIcon} alt="upload" />
          </Icon>
          <UploadText>
            Drag and drop files, or
            <span style={{ color: "#2F80ED" }}> Browse</span>
          </UploadText>
          <SupportText>Supports pdf, doc, xlxs, txt files</SupportText>
          <SupportText style={{marginTop: "1.5rem"}}>Max files data limit: 200Mb </SupportText>
          <SupportText>Max single file size: 50Mb </SupportText>
        </DragAndDrop>
        {selectedFiles.length > 0 && (
          <>
            <UploadText style={{ marginTop: "0rem", color: "#8b9ab1" }}>Uploaded Documents</UploadText>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.4rem" }}>
              {selectedFiles.map((file, index) => (
                <FilesDiv key={index}>
                  <img src={FileIcon} alt="file" style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }} />
                  {file.name}
                  <img src={BlueCross} alt="remove" style={{ width: "1.2rem", height: "1.2rem", marginLeft: "auto", cursor: "pointer" }} onClick={() => handleRemoveFile(file.name)} />
                </FilesDiv>
              ))}
            </div>
          </>
        )}
        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <UploadTxt style={{ lineHeight: "1.5rem", fontWeight: "600", fontSize: "1rem", cursor: "pointer" }} onClick={onClose}>
            Skip
          </UploadTxt>
          <ModalBtn onClick={handleUpload}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Upload"}
          </ModalBtn>
        </div>
      </UploadContent>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  );
};

export default UploadModal;